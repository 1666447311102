import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from '@routing/router';

import { useFetchBreadcrumbs } from '@api/breadcrumbs';
import { useFetchHierarchy } from '@api/hierarchy';
import paginatedTransform from '@api/paginatedTransform';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Text from '@components/Text';
import TreeV2 from '@components/Tree/Tree.v2';
import Select, { Option } from '@components/UI/Select';
import { useUserContext } from '@context/User';
import { UserContextProps } from '@context/User/User';
import useMutation from '@hooks/useMutation';
import useNewLayout from '@hooks/useNewLayout';
import { DataSourceTypesType, isBIType } from '@models/DataSourceCredentials';
import HierarchyModelV1 from '@models/HierarchyModelV1';
import theme from '@styles/theme';
import sortByType from '@utils/sortByType';

import HierarchyLoadingSkeleton from '../HierarchyLoadingSkeleton';

import DataSourceHierarchyItem, { getHierarchyUrl } from './DataSourceHierarchyItem';

export const ERROR_MESSAGE = 'Sorry, something went wrong.';
export const NO_RESULTS_MESSAGE = 'No results.';
export const NO_INGESTED_DATA_MESSAGE = "Data wasn't ingested.";

const allowDataSource = (
  filterBy: DataSourceHierarchyProps['type'],
  type?: DataSourceTypesType,
) => {
  return filterBy === 'bi' ? isBIType(type) : !isBIType(type);
};

export const getFirstDataSourceObjectUrl = (
  data?: HierarchyModelV1,
  showSchemataPage?: boolean,
) => {
  if (data) {
    return getHierarchyUrl({
      ...data,
      dataSourceGuid: `${data.dataSourceGuid}/${data.objectType}`,
      rawDataSourceGuid: data.dataSourceGuid,
      showSchemataPage,
    });
  }

  return undefined;
};

export const getDefaultDataSourceGuid = (
  dataSources: UserContextProps['dataSources'],
  type: DataSourceHierarchyProps['type'],
) => {
  if (!dataSources) {
    return {};
  }

  const dataSourcesList = Object.values(dataSources)
    .filter((dataSource) => dataSource.shouldShow && dataSource.type !== 'airflow')
    .filter((dataSource) => allowDataSource(type, dataSource.type))
    .sort((a, b) => sortByType({ a: a.name, b: b.name }));

  return { dataSourcesList, guid: dataSourcesList?.[0]?.guid };
};

interface DataSourceActionState {
  changedOn: 'default' | 'select' | 'navigate';
  guid?: string;
}

export interface DataSourceHierarchyProps {
  type: 'dwh' | 'bi';
}

const DataSourceHierarchy: React.FC<DataSourceHierarchyProps> = ({ type }) => {
  const history = useHistory();
  const { containerId, dsGuid, guid } = useParams<{
    containerId: string;
    dsGuid: string;
    guid: string;
  }>();
  const { dataSources = {}, organization } = useUserContext();
  const { showSchemataPage } = organization?.settings ?? {};
  const { shouldUseNewLayout } = useNewLayout();

  const { dataSourcesList, guid: defaultDataSourceGuid } = getDefaultDataSourceGuid(
    dataSources,
    type,
  );
  const [activeDataSource, setActiveDataSource] = useState<DataSourceActionState>();

  const { data: metadataObjectBreadcrumbs, isFetching: isMetadataObjectBreadcrumbsFetching } =
    useFetchBreadcrumbs({
      enabled: Boolean(guid) && !dsGuid,
      params: {
        guid,
        with_data_source: true,
      },
    });

  const rootBreadcrumb = metadataObjectBreadcrumbs?.results?.[0];
  const dataSourceGuid = dsGuid || rootBreadcrumb?.targetDataSourceGuid;
  const dataSourceType = dataSources[dsGuid]?.type || rootBreadcrumb?.targetDataSourceType;

  /**
   * Sets an active DataSource in dropdown.
   */
  useEffect(() => {
    const allowed = allowDataSource(type, dataSourceType);

    if (dataSourceGuid && allowed) {
      setActiveDataSource({
        changedOn: 'navigate',
        guid: dataSourceGuid,
      });
    }
  }, [dataSourceGuid, dataSourceType, type]);

  const {
    data: rootLevelTreeData,
    isError,
    isLoading,
  } = useFetchHierarchy({
    enabled:
      Boolean(activeDataSource?.guid || defaultDataSourceGuid) &&
      !isMetadataObjectBreadcrumbsFetching,
    params: {
      guid: activeDataSource?.guid || defaultDataSourceGuid,
    },
  });

  /** Opens the first page on DS select. */
  useEffect(() => {
    const firstDataSourceObjectUrl = getFirstDataSourceObjectUrl(
      rootLevelTreeData?.results?.[0],
      showSchemataPage,
    );

    if (activeDataSource?.changedOn === 'select' && firstDataSourceObjectUrl) {
      history.push(firstDataSourceObjectUrl);
    }
  }, [activeDataSource?.changedOn, history, rootLevelTreeData?.results, showSchemataPage]);

  const { mutateAsync } = useMutation({
    method: 'GET',
    url: '/hierarchy/',
  });

  const expandedParentKeys = metadataObjectBreadcrumbs?.results?.map((o) => o.targetGuid) ?? [];
  const currentItemGuid = dsGuid ? `${dsGuid}.${containerId}` : guid;

  const dataSourcesOptions = (dataSourcesList ?? []).map((item) => ({
    icon: item.dataTypes?.icons.dataSource!,
    text: item.name,
    value: item.guid,
  }));

  const selectedDataSource = dataSourcesOptions.find(
    (item) => item.value === (activeDataSource?.guid ?? defaultDataSourceGuid),
  );

  return (
    <>
      <Box mb={1} px={2}>
        {dataSourcesList && dataSourcesList.length > 0 ? (
          <Select
            containerId="AppMainSidebarSecondLevelResizableNav"
            label="data-source-hierarchy"
            maxOptionsVisible={10}
            onChange={(newValue) => {
              const [selected] = newValue as Option[];
              setActiveDataSource({ changedOn: 'select', guid: selected.value as string });
            }}
            options={dataSourcesOptions}
            value={selectedDataSource ? [selectedDataSource] : undefined}
          />
        ) : (
          <Alert bgColor="transparent" p={0} py={1}>
            {NO_INGESTED_DATA_MESSAGE}
          </Alert>
        )}
      </Box>
      {isLoading && <HierarchyLoadingSkeleton />}
      {!isLoading && rootLevelTreeData?.results?.length === 0 && (
        <Text color="inherit" fontSize={theme.typography.fontSizes.body2} px={2}>
          {NO_RESULTS_MESSAGE}
        </Text>
      )}
      {!isLoading && isError && (
        <Text color="inherit" fontSize={theme.typography.fontSizes.body2} px={2}>
          {ERROR_MESSAGE}
        </Text>
      )}
      <Box
        compDisplay={isLoading ? 'none' : 'block'}
        compHeight="100%"
        overflowX="hidden"
        overflowY="auto"
      >
        <TreeV2
          allowCaret={({ original }) => Boolean(original.hasChildren)}
          allowDataLoad={({ original }) => Boolean(original.hasChildren)}
          expandedKeys={[...expandedParentKeys, currentItemGuid]}
          getKey={(item) => item.guid}
          highlightedKeys={[currentItemGuid]}
          onDataLoad={async ({ original }) => {
            const result = await mutateAsync({ guid: original.guid });
            return paginatedTransform(HierarchyModelV1)(result)?.results;
          }}
          renderLabel={({ level, original }, isCaretVisible) => {
            return (
              <DataSourceHierarchyItem
                dataSourceGuid={`${original.dataSourceGuid}/${original.objectType}`}
                dataSourceType={original.dataSourceType}
                dataType={original.dataType}
                dataTypes={original.dataTypes}
                guid={original.guid}
                isCaretVisible={isCaretVisible}
                level={level}
                name={original.name}
                objectType={original.objectType}
                rawDataSourceGuid={original.dataSourceGuid}
                showSchemataPage={showSchemataPage}
                useNewLayout={shouldUseNewLayout}
              />
            );
          }}
          treeData={rootLevelTreeData?.results}
          useNewLayout={shouldUseNewLayout}
        />
      </Box>
    </>
  );
};

export default DataSourceHierarchy;
