import React, { useEffect } from 'react';
import { useParams, useRouteMatch } from '@routing/router';
import getDebug from 'debug';
import { useRecoilState } from 'recoil';

import { useFetchDatabase } from '@api/databases';
import { useFetchFolder } from '@api/folder';
import invalidateCache from '@api/invalidateCache';
import { useFetchSchema } from '@api/schema/schema';
import { metadataType } from '@atoms';
import NotFoundError from '@components/Error/NotFoundError';
import { FilterOptions } from '@utils/filters';
import stripSpaces from '@utils/stripSpaces';

import ColumnsPage from './ColumnsPage';
import TablesPage from './TablesPage';
import { getTablesPageRoutedConfig } from './TablesPageRouted.config';
import { TablePageProps } from './types';

const debug = getDebug('selectstar:table-page-routed');

export const tableRequestConfig: FilterOptions = {
  order: '-popularity',
  page: 1,
  page_size: 100,
  query: stripSpaces(`{
    guid,
    schema{
      name
    },
    database{
      name,
      data_source{
        guid
      }
    },
    data_types,
    name,
    table_type,
    description,
    suggested_description,
    suggested_description_source_object,
    business_owner,
    technical_owner,
    popularity,
    tagged_items,
    is_hidden,
    downstream_objects_counts,
    upstream_objects_counts,
    cloud_object,
    breadcrumbs,
    row_count,
    bytes,
    description_source,
    ai_description,
    ingested_description,
    suggested_description_source,
    user_description,
    richtext_description
  }`),
};

export const columnRequestConfig: FilterOptions = {
  order: '-popularity,name',
  page: 1,
  page_size: 100,
  query: stripSpaces(`{
    guid,
    name,
    table{
      guid,
      name,
      database{
        guid,
        name,
        data_source{
          guid,
          name
        }
      }
    },
    description,
    richtext_description,
    suggested_description,
    suggested_description_source_object,
    search_name,
    ordinal,
    field_type,
    external_type,
    extra_data,
    data_type,
    is_join_key,
    is_foreign_key,
    is_primary_key,
    foreign_keys,
    is_mention,
    is_metric,
    popularity,
    tagged_items,
    breadcrumbs,
    downstream_objects_counts,
    upstream_objects_counts,
    description_source,
    ai_description,
    ingested_description,
    suggested_description_source,
    user_description,
    data_types,
  }`),
};

export const schemaQuery = stripSpaces(`{
    breadcrumbs,
    business_owner,
    data_types,
    database,
    deactivation_scheduled_on,
    description,
    guid,
    name,
    popularity,
    richtext_description,
    search_name,
    tagged_items,
    technical_owner,
 }`);

export const databaseQuery = stripSpaces(`{
    breadcrumbs,
    business_owner,
    data_types,
    description,
    guid,
    name,
    popularity,
    richtext_description,
    search_name,
    tagged_items,
    technical_owner,
 }`);

const pageForType: {
  [key: string]: (pageInterface: TablePageProps) => {};
} = {
  columns: (args) => <ColumnsPage key={args.guid} {...args} />,
  tables: (args) => <TablesPage key={args.guid} {...args} />,
};

const TablesPageRouted: React.FC = React.memo(() => {
  const match = useRouteMatch();
  const { guid } = useParams<{ guid: string }>();
  const [metadata, setMetadataType] = useRecoilState(metadataType);

  const defaultPage = pageForType.tables;
  const page = pageForType[metadata] || defaultPage;

  const defaultConfig =
    metadata === 'columns' ? { ...columnRequestConfig } : { ...tableRequestConfig };

  const { path } = match;
  debug('metadata', metadata);

  const { data: database, isError: isDatabaseError } = useFetchDatabase(guid, {
    enabled: path === '/databases/:guid',
    params: {
      query: databaseQuery,
    },
  });

  const { data: schema, isError: isSchemaError } = useFetchSchema(guid, {
    enabled: path === '/schemas/:guid',
    params: {
      query: schemaQuery,
    },
  });

  const { data, isError: isFolderError } = useFetchFolder(guid, {
    enabled: path === '/projects/:guid',
  });

  const isError = isDatabaseError || isSchemaError || isFolderError;

  useEffect(() => {
    if (!['tables', 'columns'].includes(metadata)) {
      // set default
      setMetadataType('tables');
    }
  }, [metadata, setMetadataType]);

  if ([!guid, !defaultConfig, isError].some((condition) => Boolean(condition))) {
    return <NotFoundError />;
  }

  if (path === '/schemas/:guid') {
    defaultConfig.schema = [guid];
    const staticProps = getTablesPageRoutedConfig(schema?.dataTypes?.dataSourceType, metadata);
    const invalidateSchemaData = () => invalidateCache((keys) => [keys.schemas.schema(guid)]);
    return (
      <>
        {page({
          ...staticProps,
          breadcrumbs: schema?.breadcrumbList,
          businessOwner: schema?.businessOwner,
          data: schema,
          dataSourceData: schema,
          dataSourceType: schema?.dataTypes?.dataSourceType,
          defaultConfig,
          description: schema?.richtextDescription ?? schema?.description,
          fullName: schema?.fullName,
          guid,
          icon: schema?.icons?.objectType,
          objectType: schema?.dataTypes?.objectType,
          popularity: schema?.popularity,
          reloadData: invalidateSchemaData,
          showFilters: true,
          showObjectBreadcrumbs: true,
          supIcon: schema?.icons?.dataSource,
          supTitle: schema?.database?.name,
          taggedItems: schema?.taggedItems,
          technicalOwner: schema?.technicalOwner,
          title: schema?.name,
          tooltipText: schema?.dataTypes?.tooltips.objectType,
        })}
      </>
    );
  }
  if (path === '/databases/:guid') {
    defaultConfig.databases = [guid];
    const dataSource = database?.dataSource;
    const invalidateDatabaseData = () => invalidateCache((keys) => [keys.databases.database(guid)]);
    const staticProps = getTablesPageRoutedConfig(database?.dataTypes?.dataSourceType, metadata);

    return (
      <>
        {page({
          ...staticProps,
          breadcrumbs: database?.breadcrumbList,
          businessOwner: database?.businessOwner,
          data: database,
          dataSourceData: database,
          dataSourceType: database?.dataTypes?.dataSourceType,
          defaultConfig,
          description: database?.richtextDescription ?? database?.description,
          fullName: database?.fullName,
          guid,
          icon: database?.dataTypes?.icons.objectType,
          objectType: database?.dataTypes?.objectType,
          popularity: database?.popularity,
          reloadData: invalidateDatabaseData,
          showFilters: true,
          showObjectBreadcrumbs: true,
          supIcon: database?.dataTypes?.icons.dataSource,
          supTitle:
            dataSource?.type &&
            dataSource?.type?.charAt(0).toUpperCase() + dataSource?.type?.slice(1),
          taggedItems: database?.taggedItems,
          technicalOwner: database?.technicalOwner,
          title: database?.name,
          tooltipText: database?.dataTypes?.tooltips.objectType,
        })}
      </>
    );
  }

  if (path === '/projects/:guid') {
    defaultConfig.folders = [guid];
    const staticProps = getTablesPageRoutedConfig('dbt', metadata);

    return (
      <>
        {page({
          ...staticProps,
          breadcrumbs: data?.breadcrumbList,
          dataSourceType: data?.dataSource?.type,
          defaultConfig,
          description: data?.richtextDescription || data?.description,
          fullName: data?.fullName,
          guid,
          icon: data?.dataTypes?.icons.objectType,
          showFilters: true,
          showObjectBreadcrumbs: true,
          supIcon: data?.dataTypes?.icons.dataSource,
          supTitle: data?.dataSource.name,
          taggedItems: null,
          title: data?.name,
          tooltipText: data?.dataTypes?.tooltips.objectType,
        })}
      </>
    );
  }

  return <TablesPage defaultConfig={defaultConfig} guid={guid} />;
});

export default TablesPageRouted;
