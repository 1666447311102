import { useUserContext } from '@context/User';
import flags from '@features';

const useNewLayout = () => {
  const { organization } = useUserContext();
  const { useNewLayout: useNewLayoutBeFlag } = organization?.settings ?? {};
  const { use_new_layout_fe: useNewLayoutFeFlag } = flags;

  const shouldUseNewLayout = useNewLayoutBeFlag && useNewLayoutFeFlag;

  return {
    shouldUseNewLayout,
    useNewLayoutBeFlag,
    useNewLayoutFeFlag,
  };
};

export default useNewLayout;
