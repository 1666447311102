import React, { useEffect, useState } from 'react';

import { ReactComponent as AiIcon } from '@components/UI/Icon/sprites/individual-icons/ai.svg';
import useLocalStorage from '@utils/useLocalStorage';

import ChatBotHierarchy from '../Hierarchy/ChatbotHierarchy';
import DocumentsHierarchy from '../Hierarchy/DocumentsHierarchy';
import FavoritesHierarchy from '../Hierarchy/FavoritesHierarchy';
import TagsHierarchy from '../Hierarchy/TagsHierarchy';
import TeamsMenu from '../TeamsMenu';

import { StyledAppMainSidebar } from './AppMainSidebar.styles';
import { SidebarItemOnClickParams, SidebarSection } from './AppMainSidebar.types';
import PrimarySidebar from './PrimarySidebar';
import SecondarySidebar from './SecondarySidebar';

const defaultSections: SidebarSection[] = [
  {
    id: 'generalLinks',
    items: [
      {
        children: <ChatBotHierarchy />,
        getTo: ({ currentPath }) => {
          return currentPath.startsWith('/chatbot') ? undefined : '/chatbot';
        },
        icons: {
          active: <AiIcon height="20px" width="20px" />,
          default: <AiIcon height="20px" width="20px" />,
        },
        id: 'chatbot',
        label: 'Ask AI',
      },
      {
        children: <FavoritesHierarchy />,
        icons: {
          active: 'favorite',
          default: 'favorite-outlined',
        },
        id: 'favorites',
        label: 'Favorites',
      },
    ],
  },
  {
    id: 'docsTagsTeams',
    items: [
      {
        children: <DocumentsHierarchy />,
        getTo: () => '/docs/tabs',
        icons: {
          active: 'all-docs',
          default: 'all-docs',
        },
        id: 'docs',
        label: 'Docs',
      },
      {
        children: <TagsHierarchy />,
        getTo: () => '/tags',
        icons: {
          active: 'tag',
          default: 'tag-outline',
        },
        id: 'tags',
        label: 'Tags',
      },
      {
        children: <TeamsMenu />,
        getTo: () => '/teams',
        icons: {
          active: 'team-filled',
          default: 'team',
        },
        id: 'teams',
        label: 'Teams',
      },
    ],
  },
];

interface AppMainSidebarProps {
  sections?: SidebarSection[];
}

const AppMainSidebar = ({ sections = defaultSections }: AppMainSidebarProps) => {
  const [activeMenuItemId, setActiveMenuItemId] = useLocalStorage(
    'activeMenuItemIdV1', // added this V1 for now to avoid problems when turning on and off the flag
    sections?.[0]?.items?.[0]?.id ?? '',
  );

  const [isPrimarySidebarCollapsed, setIsPrimarySidebarCollapsed] = useState(
    Boolean(activeMenuItemId),
  );

  const handleHomeClick = () => {
    setActiveMenuItemId('');
    setIsPrimarySidebarCollapsed(false);
  };

  useEffect(() => {
    setIsPrimarySidebarCollapsed(Boolean(activeMenuItemId));
  }, [activeMenuItemId]);

  const finalSections = sections.map((section) => ({
    ...section,
    items: section.items.map((item) => ({
      ...item,
      onClick: (params: SidebarItemOnClickParams) => {
        item.onClick?.(params);
        setIsPrimarySidebarCollapsed(true);
      },
    })),
  }));

  return (
    <StyledAppMainSidebar>
      <PrimarySidebar
        activeMenuItemId={activeMenuItemId}
        isCollapsed={isPrimarySidebarCollapsed}
        onHomeClick={handleHomeClick}
        sections={finalSections}
        setActiveMenuItemId={setActiveMenuItemId}
      />
      <SecondarySidebar
        activeMenuItemId={activeMenuItemId}
        isVisible={isPrimarySidebarCollapsed}
        sections={finalSections}
      />
    </StyledAppMainSidebar>
  );
};

export default AppMainSidebar;
