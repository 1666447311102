import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from '@routing/router';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import { GridContainer } from '@components/Grid';
import NavigationBlocker from '@components/NavigationBlocker';
import useStickyContext from '@components/Sticky';
import Text from '@components/Text';
import Title from '@components/Title/Title';
import { renderInfoToast } from '@components/Toast';
import HR from '@components/UI/HR';
import MetadataDecorator from '@utils/MetadataDecorator';

import { defaultFlagState, localFlagsToObject } from './featureFlags';
import featureFlagsList from './featureFlagsList';
import FeatureFlagsTable from './FeatureFlagsTable';

const FeatureFlagsPage: React.FC = () => {
  const history = useHistory();
  const { search: searchLocation } = useLocation();
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState<{ [key: string]: boolean }>({});
  const [initialSelectedRows, setInitialSelectedRows] = useState<{ [key: string]: boolean }>({});

  const stickyProps = useStickyContext({ id: 'feature-flags-header' });

  const handleApplyFeatureFlags = () => {
    const newFlags = featureFlagsList
      .filter((flag) => selectedRowIds[flag.value] && !defaultFlagState(flag.value))
      .map((flag) => flag.value)
      .join(',');

    localStorage.setItem('flags', newFlags);

    setShouldBlockNavigation(false);
    history.push('/feature-flags?shouldShowSuccessMessage=true');
    window.location.reload();
  };

  const handleSetSelectedRows = (newSelectedRows: { [key: string]: boolean }) => {
    if (Object.keys(newSelectedRows).length === 0) {
      featureFlagsList.forEach((flag) => {
        if (defaultFlagState(flag.value)) {
          // eslint-disable-next-line no-param-reassign
          newSelectedRows[flag.value] = true;
        }
      });
    } else {
      featureFlagsList.forEach((flag) => {
        if (defaultFlagState(flag.value)) {
          // eslint-disable-next-line no-param-reassign
          newSelectedRows[flag.value] = true;
        }
      });
    }

    setSelectedRowIds(newSelectedRows);
  };

  useEffect(() => {
    const params = new URLSearchParams(searchLocation);
    const shouldShowSuccessMessage = params.get('shouldShowSuccessMessage') || undefined;
    if (shouldShowSuccessMessage) {
      history.push('/feature-flags');
      renderInfoToast('Feature flags applied');
    }
  }, []);

  useEffect(() => {
    const initialFeatureFlags = localFlagsToObject();
    setSelectedRowIds(initialFeatureFlags);
    setInitialSelectedRows(initialFeatureFlags);
  }, []);

  useEffect(() => {
    if (!shouldBlockNavigation) {
      let blockNavigation = false;
      Object.keys(initialSelectedRows).forEach((flag) => {
        if (initialSelectedRows[flag] !== selectedRowIds[flag]) {
          blockNavigation = true;
        }
      });
      setShouldBlockNavigation(blockNavigation);
    }
  }, [initialSelectedRows, selectedRowIds, shouldBlockNavigation]);

  return (
    <>
      <MetadataDecorator title="Feature Flags" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <Box {...stickyProps}>
          <Box backgroundColor="white" compDisplay="flex" justifyContent="space-between" py={2}>
            <Title>Feature Flags</Title>
            <Button mt={4} onClick={handleApplyFeatureFlags}>
              Save changes
            </Button>
          </Box>
        </Box>
        <Box mt={5}>
          <Text fontSize="h2">In development</Text>
          <FeatureFlagsTable
            data={featureFlagsList.filter(
              (flag) => !defaultFlagState(flag.value) && !flag.internalTool,
            )}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={handleSetSelectedRows}
          />
        </Box>
        <Box compDisplay="flex" flexDirection="column" mt={2}>
          <Text fontSize="h2">Internal tools</Text>
          <FeatureFlagsTable
            data={featureFlagsList.filter((flag) => flag.internalTool)}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={handleSetSelectedRows}
          />
        </Box>
        <HR mt={5} />
        <Box compDisplay="flex" flexDirection="column">
          <Text fontSize="h2">Default on</Text>
          <Text fontSize="body2">You cannot disable these feature flags.</Text>
          <FeatureFlagsTable
            data={featureFlagsList.filter((flag: { value: string }) =>
              defaultFlagState(flag.value),
            )}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={handleSetSelectedRows}
          />
        </Box>
      </GridContainer>
      <NavigationBlocker
        message="You have unsaved changes. Are you sure you want to leave this page?"
        shouldBlockNavigation={shouldBlockNavigation}
      />
    </>
  );
};

export default FeatureFlagsPage;
