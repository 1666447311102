import styled from '@emotion/styled';

import Box from '@components/Box';

interface StyledMainInputProps {
  isMultiString?: boolean;
}

export const StyledMainInput = styled(Box)<StyledMainInputProps>`
  display: flex;
  width: 100%;
  flex-wrap: ${({ isMultiString }) => (isMultiString ? '' : 'wrap')};
  position: relative;
  overflow: hidden;
  align-items: center;
  gap: ${({ theme }) => theme.space(0.5)};
`;

interface StyledMainInputContainerProps {
  allowFullWidth?: boolean;
  hasSelectedValue: boolean;
}

export const StyledMainInputContainer = styled(Box)<StyledMainInputContainerProps>`
  cursor: inherit;
  ${({ hasSelectedValue }) =>
    hasSelectedValue &&
    `
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
  `}

  width: ${({ allowFullWidth }) => (allowFullWidth ? '100%' : 'auto')};
`;

StyledMainInputContainer.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
};

export const StyledMainInputField = styled.input`
  color: inherit;
  background: 0px center;
  opacity: 1;
  width: 100%;
  grid-area: 1 / 2 / auto / auto;
  font-weight: inherit;
  font: inherit;
  min-width: 2px;
  border: 0px;
  margin: 0px;
  outline: 0px;
  padding: 0px;
  cursor: inherit;
`;

interface StyledMainInputFieldContainerProps {
  allowFullWidth?: boolean;
  hasDecorationIcon?: boolean;
  hide?: boolean;
}

const calculateContainerDisplayStyle = (hide?: boolean) => (hide ? 'none' : 'flex');

export const StyledMainInputFieldContainer = styled(Box)<StyledMainInputFieldContainerProps>`
  cursor: inherit;
  flex: 1 1 auto;
  display: ${({ hide }) => (hide ? 'none' : 'inline-grid')};
  height: 26px;
  margin-left: ${({ hasDecorationIcon, theme }) => theme.space(hasDecorationIcon ? 0.5 : 0)};

  ${({ allowFullWidth, hide }) =>
    allowFullWidth
      ? `
        width: 100%;
        display: ${calculateContainerDisplayStyle(hide)};

        & input {
          &::placeholder {
            width: 0;
          }
        }
    `
      : `
      grid-area: 1 / 1 / 2 / 3;
      grid-template-columns: 0px min-content;

      &::after {
        content: attr(data-value) ' ';
        visibility: hidden;
        white-space: pre;
        grid-area: 1 / 2 / auto / auto;
        min-width: 2px;
      }
  `}
`;

interface StyledMainInputPlaceholderProps {
  hasLeftIcon?: boolean;
  isDropdown?: boolean;
}

export const StyledMainInputPlaceholder = styled(Box)<StyledMainInputPlaceholderProps>`
  position: ${({ isDropdown }) => (isDropdown ? 'unset' : 'absolute')};
  margin-left: ${({ isDropdown, theme }) => (isDropdown ? theme.space(0.5) : 'unset')};
  height: 100%;
  display: flex;
  align-items: center;
  left: ${({ hasLeftIcon, theme }) => (hasLeftIcon ? theme.space(2.5) : 0)};
  color: ${({ isDropdown, theme }) => theme.colors.v1.gray[isDropdown ? 700 : 300]};
  user-select: none;
  pointer-events: none;
`;
