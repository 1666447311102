import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface StyledAppMainSidebarProps {
  top?: number;
}

export const StyledAppMainSidebar = styled.aside<StyledAppMainSidebarProps>`
  display: flex;
  height: 100vh;
  position: sticky;
  z-index: ${({ theme }) => theme.zIndex.sidebar};
  top: 0;
  ${({ top }) =>
    top &&
    css`
      top: ${top}px;
      height: calc(100vh - ${top}px);
    `};
`;
