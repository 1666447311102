import React, { useRef } from 'react';
import * as CSS from 'csstype';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import Portal from '@components/Portal/Portal';
import { TreeKey } from '@components/Tree/Tree.v2/types';
import { Checkbox } from '@components/UI/Form/Checkbox';
import HR from '@components/UI/HR';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import Option from '../Option';
import SearchInput from '../SearchInput';
import { calculateOptionListMaxHeight, isMatch } from '../Select/Select.utils';
import type { Option as OptionType } from '../types';
import { GetItemPropsParams } from '../useSelect';

import {
  NoResultsMessage,
  SelectAllOption,
  StyledButton,
  StyledOptionsList,
  StyledOptionsListItem,
} from './OptionsList.styles';
import TreeOptions from './TreeOptions';

export interface OptionsListProps {
  expandedKeys?: TreeKey[];
  filteredItems: OptionType[];
  flatOptions?: OptionType[];
  getItemProps: (params: GetItemPropsParams) => Record<string, any>;
  highlightedIndex: number;
  id?: string;
  inputProps?: Record<string, any>;
  inputValue: string;
  isAllSelected?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  isOpen?: boolean;
  isTree?: boolean;
  maxHeight?: CSS.Property.MaxHeight;
  maxOptionsVisible?: number;
  menuProps?: Record<string, any>;
  options: OptionType[];
  optionsListFooterElement?: React.ReactNode;
  optionsListHeaderElement?: React.ReactNode;
  renderCustomContainer?: (value: { children: React.ReactNode }) => JSX.Element;
  renderCustomOption?: (value: { option: OptionType }) => void;
  renderEmptyMessage?: (searchInputValue?: string) => React.ReactNode;
  resetSelect?: () => void;
  searchPlaceholder?: string;
  selectedItems?: OptionType[];
  setInputValue?: (value: string) => void;
  setSelectedItems?: (items: OptionType[]) => void;
  showClearSelection?: boolean;
  showSearch?: boolean;
  showSelectAllButton?: boolean;
  showSelectAllCount?: boolean;
  updateFlatOptions?: (expandedOption: OptionType, isExpanded: boolean) => void;
  wrapOptionText?: OptionType['wrapOptionText'];
}

const OptionsList = ({
  expandedKeys,
  filteredItems,
  flatOptions,
  getItemProps,
  highlightedIndex,
  inputProps,
  inputValue,
  isAllSelected,
  isLoading,
  isMulti,
  isOpen,
  isTree,
  maxHeight,
  maxOptionsVisible,
  menuProps = {},
  options,
  optionsListFooterElement = null,
  optionsListHeaderElement = null,
  renderCustomContainer,
  renderCustomOption,
  renderEmptyMessage,
  resetSelect,
  searchPlaceholder,
  selectedItems = [],
  setInputValue,
  setSelectedItems,
  showClearSelection,
  showSearch,
  showSelectAllButton,
  showSelectAllCount,
  updateFlatOptions,
  wrapOptionText,
}: OptionsListProps) => {
  const scrollBoxRef = useRef<HTMLDivElement>(null);

  const scrollBoxMaxHeight = calculateOptionListMaxHeight(scrollBoxRef.current, maxOptionsVisible);

  const renderOptions = () => {
    if (isTree && updateFlatOptions && flatOptions) {
      return (
        <TreeOptions
          expandedKeys={expandedKeys}
          filteredItems={filteredItems}
          flatOptions={flatOptions}
          getItemProps={getItemProps}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          updateFlatOptions={updateFlatOptions}
        />
      );
    }

    return (
      <>
        {filteredItems.map((option) => {
          const itemIndex = options.findIndex((item) => item.value === option.value);
          const itemProps = getItemProps({
            index: itemIndex,
            item: option,
          });
          const isOptionSelected = !isMulti && option.value === selectedItems?.[0]?.value;
          return (
            <StyledOptionsListItem
              key={option.value}
              aria-checked={isMulti ? undefined : isOptionSelected}
              aria-label={option.text}
              bordered={option.bordered}
              compWidth="100%"
              isHighlighted={highlightedIndex === itemIndex}
              isSelected={isOptionSelected}
              px={isMulti ? 1.25 : 1.75}
              {...itemProps}
            >
              {renderCustomOption?.({ option }) ?? (
                <>
                  <Box alignItems="center" compDisplay="flex" gap={1} overflow="hidden">
                    {isMulti && (
                      <Checkbox
                        checked={selectedItems.some((selected) => isMatch(selected, option))}
                        role="checkbox"
                      />
                    )}
                    <Option {...option} wrapOptionText={wrapOptionText} />
                  </Box>
                  {isOptionSelected && <Icon color={theme.colors.black} name="check" size="14px" />}
                </>
              )}
            </StyledOptionsListItem>
          );
        })}
      </>
    );
  };

  const handleSelectAllItems = () => {
    setSelectedItems?.(isAllSelected ? [] : filteredItems);
    if (!isAllSelected) {
      setInputValue?.('');
    }
  };

  const shouldShowOptions = isOpen && (options.length > 0 || isLoading || inputValue.length > 0);

  if (!shouldShowOptions) return null;

  const emptyMessage = renderEmptyMessage?.(inputValue) ?? null;

  let content = (
    <StyledOptionsList {...menuProps}>
      <>
        {optionsListHeaderElement}
        <Box compDisplay={showSearch ? undefined : 'none'} p={1}>
          <SearchInput
            onClear={() => {
              setInputValue?.('');
            }}
            variantColor="gray"
            {...(showSearch ? { ...inputProps, placeholder: searchPlaceholder } : {})}
          />
        </Box>
        {isOpen && (
          <>
            {showClearSelection &&
              resetSelect &&
              selectedItems.length > 0 &&
              filteredItems.length > 0 && (
                <>
                  <StyledButton
                    color="gray.700"
                    onClick={() => {
                      resetSelect();
                    }}
                    variant="text"
                  >
                    Clear Selection
                  </StyledButton>
                  <HR mb={0} mt={0} />
                </>
              )}
            {isMulti && showSelectAllButton && filteredItems.length > 0 && (
              <SelectAllOption onClick={handleSelectAllItems}>
                <Box alignItems="center" compDisplay="flex" gap={1}>
                  <Checkbox checked={isAllSelected} />
                  Select All {showSelectAllCount && `(${filteredItems.length})`}
                </Box>
              </SelectAllOption>
            )}
            <Box
              ref={scrollBoxRef}
              maxHeight={maxHeight ?? scrollBoxMaxHeight}
              noDefault
              overflow="auto"
              overscrollBehavior="contain"
            >
              {filteredItems.length
                ? renderOptions()
                : !isLoading &&
                  (emptyMessage || (
                    <>
                      <HR mb={0} mt={0} />
                      <NoResultsMessage>No matches</NoResultsMessage>
                    </>
                  ))}
              {isLoading ? (
                <StyledOptionsListItem alignItems="center" justifyContent="center">
                  <CircularLoader compDisplay="inline" compSize={2.5} />
                </StyledOptionsListItem>
              ) : undefined}
            </Box>
          </>
        )}
        {optionsListFooterElement}
      </>
    </StyledOptionsList>
  );

  if (renderCustomContainer) {
    content = renderCustomContainer({ children: content });
  }

  return <Portal>{content}</Portal>;
};

export default OptionsList;
