import React from 'react';

import Box from '@components/Box';
import formatNumber from '@utils/formatNumber';

interface FormattedNumberCellProps {
  number?: number | string;
}

const FormattedNumberCell = ({ number = 0 }: FormattedNumberCellProps) => {
  return (
    <Box compWidth="100%" fontSize="inherit" textAlign="right">
      {formatNumber(number)}
    </Box>
  );
};

export default FormattedNumberCell;
