import React, { useEffect } from 'react';
import { NO_LINK } from '@configs/urls/config';
import createDebug from 'debug';
import { Item as ItemType } from 'react-simple-tree-menu';

import Box from '@components/Box';
import Highlighter from '@components/Highlighter';
import IconButton from '@components/IconButton';
import OpenInNewTabButton from '@components/OpenInNewTabButton';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import UsageType from '@components/UsageType';
import flags from '@features';
import DataTypesModel from '@models/DataTypesModel';
import theme from '@styles/theme';
import { urlFor } from '@utils/routing';

import ExpandCollapseButton from './ExpandCollapseButton';
import { ExpandAllState } from './ExpandCollapseButton/ExpandCollapseButton';
import type { TreeColumnsConfigResult } from './getTreeColumnsConfig';
import {
  identSize,
  StyledSidebarTreeCarret,
  StyledSidebarTreeDescription,
  StyledSidebarTreeItem,
  StyledSidebarTreeLabel,
} from './SidebarTreeItem.styles';
import type { SidebarProps } from './types';

const debug = createDebug('selectstar:sidebar-tree');

interface SidebarTreeItemProps {
  dataTypes?: DataTypesModel;
  disableExpandAll?: boolean;
  enableHighlight?: boolean;
  expandAllState?: ExpandAllState;
  isExpandingAll?: boolean;
  isHidden?: boolean;
  isLoading?: boolean;
  onExpandAllClick?: () => void;
  onSqlButtonClick?: (query?: string) => void;
  searchKeyword?: string;
  showExpandAll?: boolean;
  treeColumnsConfig: TreeColumnsConfigResult;
}

const SidebarTreeItem: React.FC<SidebarTreeItemProps & ItemType & Partial<SidebarProps>> = (
  props,
) => {
  debug('SidebarTreeItemProps', props);
  const {
    dataTypes,
    description,
    direction,
    disableExpandAll = false,
    enableHighlight = true,
    expandAllState,
    focused,
    fullName,
    guid,
    hasDbtDwhLink,
    hasNodes,
    isExpandingAll,
    isHidden,
    isLoading,
    isOpen,
    label,
    level,
    loadLineage,
    loadMore,
    name,
    onClick,
    onExpandAllClick,
    onSqlButtonClick,
    popularity,
    query,
    routePath,
    searchKeyword,
    showExpandAll = false,
    tableId,
    toggleNode,
    treeColumnsConfig,
    type,
    usageType,
  } = props;
  /**
   * @todo Architecture.
   * Move url generation to Model.
   */
  const url =
    routePath ??
    urlFor({
      dataType: dataTypes?.dataType,
      dataTypes,
      guid,
      objectType: dataTypes?.objectType,
      parentGuid: tableId,
    });
  const isImplicit = dataTypes?.dataType === 'implicit';

  useEffect(() => {
    if (!isExpandingAll && loadMore) {
      loadLineage?.(tableId, direction);
    }
  }, [isExpandingAll, tableId, loadMore, direction, loadLineage]);

  return (
    <StyledSidebarTreeItem
      className={focused ? 'focused' : ''}
      isBold={level === 0}
      onClick={onClick}
    >
      {treeColumnsConfig[0] !== null && (
        <Box
          minHeight="24px"
          pl={flags.lineage_list_tree_v2 ? 0 : 2 + (level * identSize) / theme.grid.space}
          pr={2}
          py={0.6}
          {...treeColumnsConfig[0]}
        >
          {level > 0 && hasNodes && (
            <StyledSidebarTreeCarret data-testid={`caret-${guid}`} onClick={toggleNode}>
              <Icon name={isOpen ? 'caret-down' : 'caret-right'} size="14px" />
            </StyledSidebarTreeCarret>
          )}
          <Tooltip content={dataTypes?.tooltips.dataSource}>
            <Icon mr={0.25} name={dataTypes?.icons.dataSource!} size="16px" />
          </Tooltip>
          {hasDbtDwhLink && (
            <Tooltip content="dbt">
              <Icon mr={0.25} name="dbt" size="16px" />
            </Tooltip>
          )}
          <Tooltip
            content={isImplicit ? 'Temporary table (inferred)' : dataTypes?.tooltips.dataType}
          >
            <Icon name={dataTypes?.icons.dataType!} size="16px" />
          </Tooltip>
          <Tooltip content={fullName}>
            <StyledSidebarTreeLabel data-table-id={tableId}>
              {enableHighlight && searchKeyword ? (
                <Highlighter searchWords={[searchKeyword]} textToHighlight={label} />
              ) : (
                label ?? name
              )}
            </StyledSidebarTreeLabel>
          </Tooltip>
          {query && (
            <IconButton my={-0.5} onClick={() => onSqlButtonClick?.(query)}>
              <Tooltip action content="Open SQL Query">
                <Icon name="query" size="14px" />
              </Tooltip>
            </IconButton>
          )}
          {!isHidden && !isImplicit && !loadMore && level > 0 && url !== NO_LINK && (
            <OpenInNewTabButton
              my={-0.5}
              url={
                process.env.REACT_APP_CHROME_EXTENSION_BUILD
                  ? `${process.env.REACT_APP_SS_APP_URL}${url}`
                  : url
              }
            />
          )}
          {showExpandAll && (
            <Box ml={1.75}>
              <ExpandCollapseButton
                direction={direction}
                disabled={disableExpandAll}
                isColumn={type === 'column'}
                isLoading={isExpandingAll && isLoading}
                onClick={onExpandAllClick}
                state={expandAllState}
              />
            </Box>
          )}
        </Box>
      )}
      {treeColumnsConfig[1] !== null && (
        <Box {...treeColumnsConfig[1]}>
          <Tooltip content={description ?? ''}>
            <StyledSidebarTreeDescription className="description">
              {enableHighlight && searchKeyword !== undefined ? (
                <Highlighter searchWords={[searchKeyword]} textToHighlight={description} />
              ) : (
                description
              )}
            </StyledSidebarTreeDescription>
          </Tooltip>
        </Box>
      )}
      {treeColumnsConfig[2] !== null && (
        <Box pr={1} {...treeColumnsConfig[2]}>
          <UsageType direction={direction} types={usageType} />
        </Box>
      )}
      {!isImplicit && treeColumnsConfig[3] !== null && (
        <Box {...treeColumnsConfig[3]}>
          {popularity && <PopularityCell popularity={popularity} />}
        </Box>
      )}
    </StyledSidebarTreeItem>
  );
};

export default SidebarTreeItem;
