import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { ColumnData, ColumnModel } from '@api/columns/ColumnModel';
import { DatabaseData, DatabaseModel } from '@api/databases/DatabaseModel';
import type { DescriptionSource } from '@api/description/description.types';
import { DSUserData, DsUserModel } from '@api/dsusers/DsUserModel';
import { SchemaData, SchemaModel } from '@api/schema/SchemaModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { TeamModel } from '@api/teams/TeamModel';
import { ObjectType } from '@api/types';
import { UserData, UserModel } from '@api/user/UserModel';
import { MetadataObjectType } from '@atoms';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import { isWarehouseType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { MetadataModel } from '@models/MetadataModel';
import { PopularityData, PopularityModel } from '@models/PopularityModel';
import { Reference } from '@models/Reference';
import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';
import {
  getLastRefreshedDate,
  getLastUpdatedDate,
} from '@pages/TabbedTableViewPage/TableLastUpdate/TableLastUpdate';
import camelize from '@utils/camelize';
import formatByte from '@utils/formatByte';
import formatNumber from '@utils/formatNumber';
import { urlFor } from '@utils/routing';

import CloudObjectModel from './CloudObjectModel';

export enum TableType {
  ephemeral = 'ephemeral',
  incremental = 'incremental',
  seed = 'seed',
  table = 'table',
  view = 'view',
}

export interface TableData {
  columns?: ColumnData[];
  createdBy: UserData;
  createdOn: moment.Moment;
  dataSourceGuid?: string;
  database: DatabaseData;
  dbCreatedOn: moment.Moment;
  description?: string;
  dsuserCreatedBy: DSUserData;
  fullName?: string;
  guid: string;
  icon: string;
  isHidden?: boolean;
  lastQueriedOn: moment.Moment;
  lastRefreshedOn: moment.Moment;
  lastUpdatedOn: moment.Moment;
  name: string;
  popularity?: PopularityData;
  richtextDescription?: string;
  schema: SchemaData;
  suggestedDescription?: string;
  tableType?: TableType;
  updatedOn: moment.Moment;
  updatesOn: moment.Moment;
}

export interface TableExtraData {
  is_custom?: boolean;
  label?: string;
  path?: string;
}

export class TableModel {
  @Type(() => ColumnModel)
  columns?: ColumnModel[];

  static objectType: ObjectType = 'table';

  objectType: ObjectType = TableModel.objectType;

  objectTypeV1: MetadataObjectType = 'tables';

  static typeDisplay: string = 'Table';

  typeDisplay: string = TableModel.typeDisplay;

  guid!: string;

  @Type(() => DatabaseModel)
  database!: DatabaseModel;

  @Type(() => SchemaModel)
  schema!: SchemaModel;

  name!: string;

  description?: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Expose({ name: 'suggested_description' })
  suggestedDescription?: string;

  bytes?: number;

  @Expose({ name: 'row_count' })
  rowCount?: number;

  @Expose({ name: 'created_by' })
  @Type(() => UserModel)
  createdBy?: UserModel;

  @Expose({ name: 'dsuser_created_by' })
  @Type(() => DsUserModel)
  dsuserCreatedBy?: DsUserModel;

  @Expose({ name: 'technical_owner' })
  @Type(() => Reference)
  technicalOwner?: Reference<UserModel | TeamModel>;

  @Expose({ name: 'business_owner' })
  @Type(() => Reference)
  businessOwner?: Reference<UserModel | TeamModel>;

  /**
   * Update schedule/frequency (not used)
   */
  @Expose({ name: 'updates_on' })
  @Transform((value) => (value ? moment(value) : value))
  updatesOn?: moment.Moment;

  /**
   * When it was created in Data Source
   */
  @Expose({ name: 'db_created_on' })
  @Transform((value) => (value ? moment(value) : value))
  dbCreatedOn?: moment.Moment;

  /**
   * When it was updated in Data Source
   */
  @Expose({ name: 'last_updated_on' })
  @Transform((value) => (value ? moment(value) : value))
  lastUpdatedOn?: moment.Moment;

  /**
   * When this table was modified in the datasource (without SELECT queries)
   */
  @Expose({ name: 'last_refreshed_on' })
  @Transform((value) => (value ? moment(value) : value))
  lastRefreshedOn?: moment.Moment;

  /**
   * When it was created in Select Star DB
   */
  @Expose({ name: 'created_on' })
  @Transform((value) => moment(value))
  createdOn?: moment.Moment;

  /**
   * When it was last updated in Select Star DB
   */
  @Expose({ name: 'updated_on' })
  @Transform((value) => moment(value))
  updatedOn?: moment.Moment;

  @Expose({ name: 'last_queried_on' })
  @Transform((value) => moment(value))
  lastQueriedOn?: moment.Moment;

  @Expose({ name: 'is_hidden' })
  isHidden?: boolean;

  @Expose({ name: 'table_type' })
  tableType?: TableType;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Expose({ name: 'downstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  downstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'upstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  upstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'suggested_description_source' })
  suggestedDescriptionSource?: string;

  @Expose({ name: 'suggested_description_source_object' })
  @Type(() => Reference)
  suggestedDescriptionSourceObject?: Reference<MetadataModel>;

  @Transform((value: TableExtraData) => camelize(value))
  extra?: ConvertKeysToCamelCase<TableExtraData>;

  @Expose({ name: 'data_type' })
  dataType?: string;

  @Expose({ name: 'source_tables_count' })
  sourceTablesCount?: number;

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  @Expose({ name: 'total_credits_used' })
  @Transform((value) => value ?? 0)
  totalCreditsUsed: number = 0;

  get formattedTotalCreditsUsed() {
    return formatNumber(this.totalCreditsUsed);
  }

  get dataSourceGuid() {
    return this.database?.dataSource?.guid;
  }

  /**
   * @experimental
   * Page props selection.
   * Providing additional template driven props we ensure dependency inversion
   * which would allow us to reduce templates counts using the only ObjectPage.tsx.
   */

  externalUrl?: string;

  get supTitle() {
    return this?.extra?.path || `${this?.database?.name ?? ''}.${this?.schema?.name ?? ''}`;
  }

  /** @todo Architecture. Discuss about the possibility to move the logic to the BE. */
  get owner() {
    return this?.database?.dataSource?.type === 'dbt' ? this.dsuserCreatedBy : undefined;
  }

  get created() {
    return isWarehouseType(this?.database?.dataSource?.type as any) ? undefined : this.createdOn;
  }

  get lastUpdated() {
    return getLastUpdatedDate(this);
  }

  get lastRefreshed() {
    return getLastRefreshedDate(this);
  }

  /** @todo Architecture. How to move this on the BE side. */
  get materialization() {
    return this?.database?.dataSource?.type === 'dbt' ? this?.tableType : undefined;
  }

  /** @todo Architecture. How to replace it like in MetricModel => sql? */
  showTableLastQuery = true;

  loadingStatus = undefined;

  showLinksTo = true;

  linksToObjects = undefined;

  showLinkedFrom = false;

  linkedFromObjects = undefined;

  showMentionedBy = true;

  /** Used for owners edit modal. */
  itemsType = 'tables' as const;

  connections = undefined;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  lastRun = undefined;

  color = undefined;

  /**
   * @todo Architecture.
   * Object might have the FE page link and the best place to define it is a model.
   */
  get routePath() {
    return urlFor({
      dataSourceGuid: this.dataSourceGuid,
      dataTypes: this.dataTypes,
      guid: this.guid,
      objectType: this.objectType,
    });
  }

  showEditTags = true;

  showCustomAttributes = true;

  @Expose({ name: 'cloud_object' })
  @Type(() => CloudObjectModel)
  cloudObject?: CloudObjectModel;

  rawSql = undefined;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'user_description' })
  userDescription?: string;

  showDescriptionSelector = true;

  @Expose({ name: 'total_run' })
  totalRun?: number;

  get formattedBytes() {
    if (this.bytes === null || this.bytes === undefined) {
      return undefined;
    }

    return formatByte(this.bytes);
  }

  get formattedRowCount() {
    if (this.rowCount === null || this.rowCount === undefined) {
      return undefined;
    }

    return formatNumber(this.rowCount);
  }

  get formattedTotalRun() {
    return formatNumber(this.totalRun, { notation: 'compact' });
  }

  type = undefined;
}
