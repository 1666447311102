import React from 'react';

interface GetLayoutComponentProps {
  useNewLayout?: boolean;
}

const getLayoutComponent =
  <P extends object>(OldComponent: React.ComponentType<P>, NewComponent: React.ComponentType<P>) =>
  (props: P & GetLayoutComponentProps) => {
    const { useNewLayout, ...rest } = props;
    return useNewLayout ? <NewComponent {...(rest as P)} /> : <OldComponent {...(rest as P)} />;
  };

export default getLayoutComponent;
