import type { Job as JobResponse } from '@api/openapi.generated';
import type { ObjectType } from '@api/types';
import { rawMap } from '@api/utils';
import DataTypesModel from '@models/DataTypesModel/DataTypesModel.v1';

const objectType: ObjectType = 'job';

const mapJobModel = (value: Partial<JobResponse>) => {
  return {
    dataTypes: rawMap(DataTypesModel, value.data_types),
    guid: value.guid!,
    jobUrl: value.job_url,
    name: value.name,
  };
};

export default mapJobModel;

mapJobModel.objectType = objectType;

export type JobModel = ReturnType<typeof mapJobModel>;
