import React, { useCallback, useMemo, useRef, useState } from 'react';
import { NO_DATA_TEXT } from '@constants';
import { useHistory } from '@routing/router';
import { Cell } from 'react-table';

import { useFetchDbtTests } from '@api/dbt';
import DbtTestModel from '@api/dbt/DbtTestModel';
import DateTime from '@components/DateTime';
import type { FiltersListFilterType } from '@components/FiltersList/FiltersList';
import Status from '@components/Status';
import ColumnNameTableCell from '@components/TabContent/ColumnsTab/ColumnTable/ColumnNameTableCell/ColumnNameTableCell';
import TabError from '@components/TabContent/TabError';
import LinkedCell, { TableItem } from '@components/Table/Cells/LinkedCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import { sortDates } from '@components/Table/Table/sortDates';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { useModal } from '@context/Modal';
import ColumnPage from '@pages/ColumnPage';
import type { DbtTestsCountsType } from '@pages/DbtTestsPage/DbtFiltersList';
import DbtFiltersList from '@pages/DbtTestsPage/DbtFiltersList';
import { Filter } from '@utils';
import wrapString from '@utils/wrapString';

export const dbtTestsDefaultFiltersConfig: Filter.FilterOptions = {
  order: 'completed_at',
  page: 1,
  page_size: 50,
  sortColumn: 'completed_at',
  sortDirection: 'ascending',
};

export const dbtTestsSearchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  description: 'search_description',
  name: 'search_name',
};

export const dbtTestsSortConfig: { [key: string]: string } = {
  completedAt: 'completed_at',
  name: 'name',
  status: 'status',
};

interface DbtTestsTableProps {
  dsGuid?: string;
  fGuid?: string;
  isDataSourceEditable: boolean;
  parentUrl: string;
  showColumnCategoryTags: boolean;
  tableGuid?: string;
}

const DbtTestsTable: React.FC<DbtTestsTableProps> = ({
  dsGuid,
  fGuid,
  isDataSourceEditable,
  parentUrl,
  showColumnCategoryTags,
  tableGuid,
}) => {
  const history = useHistory();
  const [currColumn, setCurrColumn] = useState<string | undefined>();
  const { MODAL_IDS, openModal } = useModal();
  const { changePage, filter, globalSearch, sort } = Filter.useUpdateFilters(
    dbtTestsDefaultFiltersConfig,
    dbtTestsSearchConfig,
    dbtTestsSortConfig,
    'completed_at',
  );
  const [searchVisible, setSearchVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<FiltersListFilterType | null>(null);
  const initialStatusCounts = useRef<DbtTestsCountsType>(null);
  const { dataSourceGuid } = useMetadataObjectContext();

  const { data, isError } = useFetchDbtTests({
    params: {
      ...Filter.setParams(filter),
      data_source: dsGuid,
      folders: fGuid,
      status: selectedFilter?.name !== 'all' ? selectedFilter?.name : undefined,
      table: tableGuid,
    },
  });

  const columns: ColumnConfig<DbtTestModel>[] = [
    {
      Header: SearchHeader,
      disableFilters: true,
      disableResizing: true,
      disableSortBy: true,
      id: 'search',
      width: 32,
    },
    {
      Cell: ({ row }: Cell<DbtTestModel>) => <Status status={row.original.latestRun?.status} />,
      Header: 'Last Run Status',
      accessor: 'status',
      id: 'status',
      width: 140,
    },
    {
      Cell: (props: Cell<DbtTestModel>) => {
        const { row } = props;

        return (
          <LinkedCell
            {...props}
            item={row?.original?.column?.table!}
            itemName={row?.original?.column?.table?.name}
            showIcon
          />
        );
      },
      Header: 'dbt Model/Table',
      accessor: (d) => d?.column?.table?.name,
      disableFilters: true,
      id: 'dbt_table',
      width: '130%',
    },
    {
      Cell: (props: Cell<DbtTestModel>) => {
        const { row } = props;

        return (
          <TableItem
            {...props}
            item={row.original}
            name={row.original.name}
            onClick={() => {
              openModal(MODAL_IDS.query, {
                codeString: row.original.latestRun?.compiledSql ?? NO_DATA_TEXT,
                hideSidebar: true,
              });
            }}
            showIcon
          />
        );
      },
      Header: `Test name ${wrapString(data?.count)}`,
      accessor: 'name',
      disableFilters: true,
      disableHiding: true,
      id: 'name',
      width: '150%',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (props: Cell<DbtTestModel>) => {
        const {
          row: { original },
        } = props;
        const columnData = original?.column;

        return (
          <ColumnNameTableCell
            columnItem={columnData}
            customUrl="#"
            isDataSourceEditable={isDataSourceEditable}
            openColumnsPage={() => {
              setCurrColumn(columnData?.guid);
            }}
            showConstraint={false}
            showCopyFullPathButton={false}
          />
        );
      },
      Header: 'Column',
      accessor: (d) => d?.column,
      disableFilters: true,
      disableSortBy: true,
      id: 'column',
      width: '120%',
    },
    {
      Cell: ({ row }: Cell<DbtTestModel>) => (
        <DateTime datetime={row.original.latestRun?.completedAt} format="fullDateFormat" />
      ),
      Header: 'Last Run',
      accessor: (d) => d?.latestRun?.completedAt?.toDate(),
      disableFilters: true,
      id: 'completedAt',
      sortType: sortDates,
      width: '120%',
    },
  ];

  const statusCounts = useMemo(() => {
    if (data?.status_counts && initialStatusCounts.current === null) {
      initialStatusCounts.current = data?.status_counts;
    }

    return initialStatusCounts.current;
  }, [data?.status_counts]);

  const hiddenColumns = useMemo(() => {
    const columnIds = ['description', 'tags'];

    if (tableGuid) {
      columnIds.push('dbt_table');
    }

    return columnIds;
  }, [tableGuid]);

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;
  const getRowId = useCallback((row: Partial<DbtTestModel>) => row.guid!, []);

  if (isError) {
    return <TabError />;
  }

  return (
    <>
      <TableStyled>
        <DbtFiltersList
          counts={statusCounts}
          onClick={(value) => {
            changePage(1);
            setSelectedFilter(value);
          }}
          selectedFilter={selectedFilter}
        />
        <Table
          basic="very"
          changePage={changePage}
          className="table-full"
          columns={columns}
          compact
          data={data?.results ?? []}
          disableRowSelect
          getRowId={getRowId}
          initialState={{
            hiddenColumns,
            sortBy: [
              {
                desc: false,
                id: 'completedAt',
              },
            ],
          }}
          loading={data?.results === undefined}
          manualGlobalFilter
          manualPagination
          selectable
          setGlobalFilter={globalSearch}
          setSortBy={sort}
          showFilter={searchVisible}
          sortable
          stickyHeader
          toggleFilter={() => setSearchVisible((prev) => !prev)}
          totalPages={totalPages}
          unstackable
        />
      </TableStyled>
      {currColumn && (
        <ColumnPage
          dsGuid={dataSourceGuid}
          guid={currColumn}
          hideCategoryTags={!showColumnCategoryTags}
          isDataSourceEditable={isDataSourceEditable}
          onClose={() => {
            setCurrColumn(undefined);
            history.push(`${parentUrl}/tests`);
          }}
        />
      )}
    </>
  );
};

export default DbtTestsTable;
