import type { GetJobsListQueryParams } from '@api/openapi.generated';
import { paginatedSelect } from '@api/utils';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { PaginatedResponse } from '@models/PaginatedResponse';

import * as cacheKeys from './cacheKeys';
import type { JobModel } from './JobModel.v1';
import mapJobModel from './JobModel.v1';

export const fetchJobsSelect = paginatedSelect(mapJobModel);
interface JobsResponse extends PaginatedResponse<JobModel> {}

export const useFetchJobs = (options: UseFetchOptions<JobsResponse, GetJobsListQueryParams>) => {
  return useFetch<JobsResponse>({
    ...options,
    queryKey: [...cacheKeys.all, options.params],
    select: fetchJobsSelect,
    url: `/jobs/`,
  });
};
