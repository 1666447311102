import React from 'react';

import { TagModel, TagType } from '@api/tags/TagModel';
import Box from '@components/Box';
import MetadataGrid from '@components/MetadataGrid';
import MetadataGridCard from '@components/MetadataGridCard';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import TitleView from '@components/UI/TitleView';
import { urlFor } from '@utils/routing';

import TagCardFooter from './TagCardFooter';
import TagsSectionEmptyState from './TagsSectionEmptyState';

interface TagsSectionProps {
  canEdit: boolean;
  loading: boolean;
  onClickAdd?: () => void;
  onClickDelete: (tag: TagModel) => void;
  onClickEdit: (tag: TagModel) => void;
  onTagPageChange: (page: number) => void;
  tags?: TagModel[];
  title: string;
  totalPages?: number;
  type: TagType;
}

const TagsSection: React.FC<TagsSectionProps> = ({
  canEdit,
  loading,
  onClickAdd,
  onClickDelete,
  onClickEdit,
  onTagPageChange,
  tags,
  title,
  totalPages,
  type,
}) => (
  <Box compDisplay="flex" flexDirection="column">
    <Box mb={0.5}>
      <TitleView mb={2.5} pt={1} title={title} />
    </Box>
    {tags?.length === 0 && !loading && (
      <TagsSectionEmptyState canAddNewTag={canEdit} onAddNewTag={onClickAdd} type={type} />
    )}
    <Box mb={tags?.length === 0 ? 0 : 2}>
      <MetadataGrid
        changePage={onTagPageChange}
        data={tags}
        enableScrollTop={false}
        isLoading={loading}
        showNoDataMessage={false}
        totalPages={totalPages}
      >
        {(item) => {
          return (
            <MetadataGridCard
              data-testid={`tag-card-${item.guid}`}
              description={item.description}
              footer={<TagCardFooter guid={item.guid} />}
              icon={
                <Box alignSelf="center">
                  <Icon color={item.color} name={item.icon} size="20px" />
                </Box>
              }
              iconColor={item.color}
              isEditable={canEdit}
              minHeight="180px"
              name={
                <>
                  {item.name}
                  <Text color="gray.400" fontWeight="medium" ml={1}>
                    {item.count}
                  </Text>
                </>
              }
              onDelete={() => onClickDelete(item)}
              onEdit={() => onClickEdit(item)}
              url={urlFor(item)}
            />
          );
        }}
      </MetadataGrid>
    </Box>
  </Box>
);

export default TagsSection;
