import React, { memo, useMemo } from 'react';
import { BaseEdge, EdgeProps as BaseEdgeProps, getBezierPath } from 'reactflow';

import { ExploreEdgeData } from '../../LineageExplore.types';
import { useLineageExplore } from '../../useLineageExplore';
import {
  COLORS,
  ColorState,
  EDGE_MARKER_COLOR_ID,
} from '../ExploreGraph/LineageExploreGraph.colors';

import { getSpecialPath } from './Edge.utils';

const EDGE_STYLES = {
  stroke: COLORS.edge,
  strokeWidth: { default: 1, focused: 1, selected: 2, unfocused: 1 },
};

interface EdgeProps extends BaseEdgeProps<ExploreEdgeData> {}

const Edge = (props: EdgeProps) => {
  const { data, id, sourcePosition, sourceX, sourceY, targetPosition, targetX, targetY } = props;
  const { isUnfocused = false, side, sourceTargetSameStack, type = 'table' } = data ?? {};
  const { focusedNodeKey, selectedEdge } = useLineageExplore();

  const edgePath = useMemo(() => {
    const edgePathParams = {
      side,
      sourcePosition,
      sourceX,
      sourceY,
      targetPosition,
      targetX,
      targetY,
    };
    let path = '';
    if (sourceTargetSameStack) {
      path = getSpecialPath(edgePathParams);
    } else {
      [path] = getBezierPath(edgePathParams);
    }

    return path;
  }, [
    side,
    sourcePosition,
    sourceTargetSameStack,
    sourceX,
    sourceY,
    targetPosition,
    targetX,
    targetY,
  ]);

  const dynamicStyles = (() => {
    let state: ColorState = 'default';
    if (isUnfocused) {
      state = 'unfocused';
    } else if (selectedEdge?.id === id) {
      state = 'selected';
    } else if (focusedNodeKey) {
      state = 'focused';
    }

    const color = COLORS.edge[type][state];
    const marker = EDGE_MARKER_COLOR_ID[type][state];

    return {
      arrowHead: `url(#${marker})`,
      stroke: color,
      strokeWidth: EDGE_STYLES.strokeWidth[state],
    };
  })();

  return (
    <BaseEdge
      {...props}
      markerEnd={dynamicStyles.arrowHead}
      path={edgePath}
      style={{
        stroke: dynamicStyles.stroke,
        strokeWidth: dynamicStyles.strokeWidth,
      }}
    />
  );
};

const MemoizedEdge = memo(Edge);

export default MemoizedEdge;
