import 'reflect-metadata';
import '@models/referenceObjectMap';
import '@utils/suppressedWarnings';

import React from 'react';
import { INTERCOM_APP_ID } from '@constants';
import createCache from '@emotion/cache';
import { CacheProvider, ThemeProvider } from '@emotion/react';
import { BrowserRouter, Route, Switch } from '@routing/router';
import { ErrorBoundary } from '@sentry/react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { IntercomProvider } from 'react-use-intercom';
import { RecoilRoot } from 'recoil';

import AppMainSidebar from '@components/AppMainSidebar';
import Boot, { PublicBoot } from '@components/Boot';
import DataProviders from '@components/DataProviders';
import UnexpectedError from '@components/Error/UnexpectedError';
import GlobalStyles from '@components/GlobalStyles';
import Layout from '@components/Layout';
import PrivateRoute from '@components/PrivateRoute';
import ScrollToTop from '@components/ScrollToTop';
import SettingsSidebar from '@components/SettingsSidebar';
import StickyProvider from '@components/Sticky/StickyProvider';
import UserPermissions from '@components/UserPermissions';
import UserRedirect from '@components/UserRedirect';
import { ModalProvider } from '@context/Modal';
import { SegmentProvider } from '@context/Segment';
import flags from '@features';
import fetchClient from '@lib/fetchClient';
import NotFoundPage from '@pages/404';
import theme from '@styles/theme';

import {
  AdminDataManagerRoutes,
  AdminRoutes,
  DataManagerRoutes,
  MainRoutes,
  OnboardingRoutes,
  SettingsRoutes,
  SignUpRoutes,
} from './Routes';

// @todo: Remove whenever possible - sc-78330 (https://app.shortcut.com/select-star/story/78330/fullstory-add-emotion-speedy-mode-again-when-possible)
const emotionCache = createCache({
  key: 'emotion-cache-no-speedy',
  speedy: false,
});

const App = () => {
  return (
    <ErrorBoundary fallback={UnexpectedError}>
      <RecoilRoot>
        <QueryClientProvider client={fetchClient}>
          <ReactQueryDevtools />
          <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={!!flags.enable_intercom}>
            <SegmentProvider segmentId={window?.env?.SEGMENT_ID}>
              <ThemeProvider theme={theme}>
                <ModalProvider>
                  <CacheProvider value={emotionCache}>
                    <StickyProvider>
                      <GlobalStyles />
                      <BrowserRouter>
                        <ScrollToTop />
                        <Switch>
                          <Route path={SignUpRoutes.map((r) => r.route)}>
                            <PublicBoot>
                              <Switch>
                                {SignUpRoutes.map((item) => (
                                  <Route
                                    key={item.route}
                                    component={item.component}
                                    exact
                                    path={item.route}
                                  />
                                ))}
                              </Switch>
                            </PublicBoot>
                          </Route>
                          <Route path={OnboardingRoutes.map((r) => r.route)}>
                            <PrivateRoute>
                              <DataProviders>
                                <UserPermissions>
                                  <Boot>
                                    <Switch>
                                      {OnboardingRoutes.map((item) => (
                                        <Route
                                          key={item.route}
                                          component={item.component}
                                          exact
                                          path={item.route}
                                        />
                                      ))}
                                    </Switch>
                                  </Boot>
                                </UserPermissions>
                              </DataProviders>
                            </PrivateRoute>
                          </Route>
                          <Route path={SettingsRoutes.map((r) => r.route)}>
                            <PrivateRoute>
                              <DataProviders>
                                <UserPermissions>
                                  <Boot>
                                    <UserRedirect>
                                      <Layout leftSidebar={<SettingsSidebar />}>
                                        <Switch>
                                          {SettingsRoutes.map((item) => (
                                            <Route
                                              key={item.route}
                                              component={item.component}
                                              exact
                                              path={item.route}
                                            />
                                          ))}
                                        </Switch>
                                      </Layout>
                                    </UserRedirect>
                                  </Boot>
                                </UserPermissions>
                              </DataProviders>
                            </PrivateRoute>
                          </Route>
                          <Route path={DataManagerRoutes.map((r) => r.route)}>
                            <PrivateRoute>
                              <DataProviders>
                                <UserPermissions dataManagerRequired>
                                  <Boot>
                                    <UserRedirect>
                                      <Layout leftSidebar={<SettingsSidebar />}>
                                        <Switch>
                                          {DataManagerRoutes.map((item) => (
                                            <Route
                                              key={item.route}
                                              component={item.component}
                                              path={item.route}
                                            />
                                          ))}
                                        </Switch>
                                      </Layout>
                                    </UserRedirect>
                                  </Boot>
                                </UserPermissions>
                              </DataProviders>
                            </PrivateRoute>
                          </Route>
                          <Route path={AdminRoutes.map((r) => r.route)}>
                            <PrivateRoute>
                              <DataProviders>
                                <UserPermissions adminRequired>
                                  <Boot>
                                    <UserRedirect>
                                      <Layout leftSidebar={<SettingsSidebar />}>
                                        <Switch>
                                          {AdminRoutes.map((item) => (
                                            <Route
                                              key={item.route}
                                              component={item.component}
                                              path={item.route}
                                            />
                                          ))}
                                        </Switch>
                                      </Layout>
                                    </UserRedirect>
                                  </Boot>
                                </UserPermissions>
                              </DataProviders>
                            </PrivateRoute>
                          </Route>
                          <Route path={AdminDataManagerRoutes.map((r) => r.route)}>
                            <PrivateRoute>
                              <DataProviders>
                                <UserPermissions adminExceptForPbacRequired>
                                  <Boot>
                                    <UserRedirect>
                                      <Layout leftSidebar={<SettingsSidebar />}>
                                        <Switch>
                                          {AdminDataManagerRoutes.map((item) => (
                                            <Route
                                              key={item.route}
                                              component={item.component}
                                              path={item.route}
                                            />
                                          ))}
                                        </Switch>
                                      </Layout>
                                    </UserRedirect>
                                  </Boot>
                                </UserPermissions>
                              </DataProviders>
                            </PrivateRoute>
                          </Route>
                          <Route path={MainRoutes.map((r) => r.route)}>
                            <PrivateRoute>
                              <DataProviders>
                                <UserPermissions>
                                  <Boot>
                                    <UserRedirect>
                                      <Layout leftSidebar={<AppMainSidebar />}>
                                        <Switch>
                                          {MainRoutes.map((item) => {
                                            const { exact = true } = item;
                                            return (
                                              <Route
                                                key={item.route}
                                                component={item.component}
                                                exact={exact}
                                                path={item.route}
                                              />
                                            );
                                          })}
                                          <Route path="/">
                                            <NotFoundPage />
                                          </Route>
                                        </Switch>
                                      </Layout>
                                    </UserRedirect>
                                  </Boot>
                                </UserPermissions>
                              </DataProviders>
                            </PrivateRoute>
                          </Route>
                        </Switch>
                      </BrowserRouter>
                    </StickyProvider>
                  </CacheProvider>
                </ModalProvider>
              </ThemeProvider>
            </SegmentProvider>
          </IntercomProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </ErrorBoundary>
  );
};

export default App;
