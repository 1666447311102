import { ExploreEdgeData } from '../../LineageExplore.types';

export type GetSpecialPathParams = {
  side?: ExploreEdgeData['side'];
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
};

const OFFSET = 21;
const TARGET_OFFSET = 1;

export const getSpecialPath = ({
  side = 'right',
  sourceX,
  sourceY,
  targetX,
  targetY,
}: GetSpecialPathParams) => {
  const centerYOffset = sourceX < targetX ? OFFSET : -OFFSET;
  const centerXOffset = side === 'right' ? OFFSET : -(2 * OFFSET);

  // Calculates the coordinates of the center of the arrow
  const centerX = (sourceX + targetX) / 2 + OFFSET;
  const centerY = (sourceY + targetY) / 2 + OFFSET;

  return `M ${sourceX} ${sourceY} Q ${centerX + centerXOffset} ${centerY + centerYOffset} ${
    targetX + TARGET_OFFSET
  } ${targetY}`;
};
