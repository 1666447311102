import 'reflect-metadata';

import React from 'react';
import { Redirect } from '@routing/router';

import PublicRoute from '@components/PublicRoute';
import UserPermissions from '@components/UserPermissions';
import AccountSettingsPage from '@pages/AccountSettingsPage';
import AdminAnalyticsPage from '@pages/Admin/AdminAnalyticsPage/AdminAnalyticsPage';
import AdminAppsPage from '@pages/Admin/AdminAppsPage';
import AdminBillingPage from '@pages/Admin/AdminBillingPage/AdminBillingPage';
import AdminCostAnalysisPage from '@pages/Admin/AdminCostAnalysisPage';
import AdminCustomAttributePage from '@pages/Admin/AdminCustomAttributePage';
import AdminDataPage from '@pages/Admin/AdminDataPage';
import AdminDataSourcePage from '@pages/Admin/AdminDataSourcePage/AdminDataSourcePage';
import AdminDiscussionPage from '@pages/Admin/AdminDiscussionPage';
import AdminGithubPage from '@pages/Admin/AdminGithubPage';
import AdminMetadataUpload from '@pages/Admin/AdminMetadataUpload';
import AdminMonteCarloPage from '@pages/Admin/AdminMonteCarloPage';
import AdminRbacPage from '@pages/Admin/AdminRBACPage';
import AdminSlackPage from '@pages/Admin/AdminSlackPage';
import AdminSSOPage from '@pages/Admin/AdminSSOPage';
import AdminTeamsPage from '@pages/Admin/AdminTeamsPage';
import AdminUsersPage from '@pages/Admin/AdminUsersPage';
import AutomatedPIIPage from '@pages/AutomatedPIIPage';
import ChatBotPage from '@pages/ChatBotPage';
import DbtTestsPage from '@pages/DbtTestsPage';
import ExtensionSignIn from '@pages/ExtensionSignIn';
import FeatureFlagsPage from '@pages/FeatureFlagsPage';
import IncompleteOrganization from '@pages/IncompleteOrganization';
import InvitationSignUpPage from '@pages/InvitationSignUpPage';
import LoginPage from '@pages/LoginPage';
import OnboardingPage from '@pages/OnboardingPage';
import PasswordChangePage from '@pages/PasswordChangePage';
import PasswordResetPage from '@pages/PasswordResetPage';
import RequestForInvitation from '@pages/RequestForInvitation';
import SendInvitationRedirectPage from '@pages/SendInvitationRedirectPage';
import SignUpPage from '@pages/SignUpPage';
import SignUpSuccessPage from '@pages/SignupSuccessPage';
import SlackAuthorizePage from '@pages/SlackAuthorizePage';
import SSORedirectPage from '@pages/SSORedirectPage';
import TeamSettingsPage from '@pages/TeamSettingsPage';

import UserSettingsPage from './pages/UserSettingsPage';
import {
  SharedMainRoutes,
  SharedOnboardingRoutes,
  SharedSettingsRoutes,
  SharedSignUpRoutes,
} from './Routes.shared';

export const SignUpRoutes = [
  ...SharedSignUpRoutes,
  {
    component: () => (
      <PublicRoute>
        <SignUpPage />
      </PublicRoute>
    ),
    route: '/signup',
  },
  {
    component: () => (
      <PublicRoute>
        <SignUpSuccessPage />
      </PublicRoute>
    ),
    route: '/signup-success',
  },
  {
    component: () => (
      <PublicRoute>
        <PasswordResetPage />
      </PublicRoute>
    ),
    route: '/password/reset/',
  },
  {
    component: () => (
      <PublicRoute>
        <PasswordChangePage />
      </PublicRoute>
    ),
    route: '/password/reset/:guid?/:token?/',
  },
  {
    component: SSORedirectPage,
    route: '/sso/redirect',
  },
  {
    component: () => (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
    route: '/login',
  },
  {
    component: () => (
      <PublicRoute shouldRedirect={false}>
        <InvitationSignUpPage />
      </PublicRoute>
    ),
    route: '/invitations/:code/',
  },
  {
    component: RequestForInvitation,
    route: '/request-invite',
  },
];

export const OnboardingRoutes = [
  ...SharedOnboardingRoutes,
  {
    component: OnboardingPage,
    exact: true,
    route: '/onboarding',
  },
  {
    component: SlackAuthorizePage,
    route: '/authorize/slack',
  },
  {
    component: IncompleteOrganization,
    exact: true,
    route: '/incomplete-organization',
  },
  {
    component: SendInvitationRedirectPage,
    route: '/send-invite',
  },
];

export const SettingsRoutes = [
  ...SharedSettingsRoutes,
  {
    component: () => <Redirect to="/settings/account" />,
    route: '/settings',
  },
  {
    component: AccountSettingsPage,
    route: '/settings/account',
  },
  {
    component: UserSettingsPage,
    route: '/settings/user',
  },
  {
    component: TeamSettingsPage,
    route: '/settings/teams/:guid?',
  },
  {
    component: () => (
      <UserPermissions staffRequired>
        <FeatureFlagsPage />
      </UserPermissions>
    ),
    route: '/feature-flags',
  },
];

export const AdminDataManagerRoutes = [
  {
    component: AdminDataPage,
    route: '/admin/data',
  },
  {
    component: AdminDataSourcePage,
    exact: false,
    route: '/admin/:dsType/:guid',
  },
  {
    component: () => <Redirect to="/admin/data" />,
    route: '/admin',
  },
];

export const AdminRoutes = [
  {
    component: AutomatedPIIPage,
    exact: false,
    route: '/admin/automated-pii',
  },
  {
    component: AdminBillingPage,
    exact: false,
    route: '/admin/billing',
  },
  {
    component: AdminCostAnalysisPage,
    exact: false,
    route: '/admin/cost-analysis',
  },
  {
    component: AdminUsersPage,
    route: '/admin/users',
  },
  {
    component: AdminTeamsPage,
    route: '/admin/teams',
  },
  {
    component: AdminCustomAttributePage,
    route: '/admin/data/custom-attribute',
  },
  {
    component: AdminSlackPage,
    route: '/admin/slack',
  },
  {
    component: AdminAppsPage,
    route: '/admin/apps',
  },
  {
    component: AdminGithubPage,
    route: '/admin/github',
  },
  {
    component: AdminMonteCarloPage,
    route: '/admin/monte-carlo',
  },
  {
    component: AdminSSOPage,
    route: '/admin/sso',
  },
  {
    component: AdminRbacPage,
    route: '/admin/access-control',
  },
  {
    component: AdminAnalyticsPage,
    route: '/admin/analytics',
  },
];

export const DataManagerRoutes = [
  {
    component: AdminMetadataUpload,
    route: '/admin/metadata-upload',
  },
  {
    component: AdminDiscussionPage,
    route: '/admin/discussion',
  },
];

export const MainRoutes = [
  {
    component: ChatBotPage,
    exact: false,
    route: '/chatbot/:guid?',
  },
  {
    component: ExtensionSignIn,
    route: '/extension-signin',
  },
  {
    component: DbtTestsPage,
    exact: false,
    route: '/dbt-tests/:dsGuid/:fGuid?/tests',
  },
  ...SharedMainRoutes,
];
