import React from 'react';

import { useFetchExploreRelatedDashboardsCsv } from '@api/explores';
import { useFetchLookMLViewRelatedDashboardsCsv } from '@api/lookML';
import { useFetchTableauDataSourceRelatedDashboardsCsv } from '@api/tableau';
import { useFetchTablesRelatedDashboardsCsv } from '@api/tables';
import { renderErrorToast } from '@components/Toast';
import ExportToCsvButton from '@pages/DocumentsPage/GlossaryTab/ExportToCsvButton';
import downloadCSV from '@utils/downloadCSV';

import { checkCsvLimit } from '../UpstreamDownstreamTab/UpstreamDownstreamTab';

interface ExportToCsvProps {
  assetName: string;
  guid: string;
  includeHidden?: boolean;
  isRelevantLineage: boolean;
  objectType?: 'explore' | 'table' | 'lookmlview' | 'tableaudatasource';
  order?: string;
}

const ExportToCsv = ({
  assetName,
  guid,
  includeHidden = true,
  isRelevantLineage,
  objectType = 'table',
  order,
}: ExportToCsvProps) => {
  const objectTypeRequestMap = {
    explore: useFetchExploreRelatedDashboardsCsv,
    lookmlview: useFetchLookMLViewRelatedDashboardsCsv,
    table: useFetchTablesRelatedDashboardsCsv,
    tableaudatasource: useFetchTableauDataSourceRelatedDashboardsCsv,
  };

  const { isLoading, refetch: fetchCsv } = objectTypeRequestMap[objectType](guid, {
    enabled: false,
    onError: (error) => {
      const errorMsg = error?.data?.[0] ?? 'Sorry, something went wrong';
      renderErrorToast(errorMsg);
    },
    onSuccess: (csvData: string) => {
      checkCsvLimit(csvData);
      downloadCSV(csvData, `${assetName}_downstream_dashboards.csv`);
    },
    params: {
      include_hidden: includeHidden,
      order,
      relevant_lineage: isRelevantLineage,
    },
    refetchOnWindowFocus: false,
  });

  const handleExportCsvClick = () => {
    fetchCsv();
  };

  return <ExportToCsvButton isLoading={isLoading} onClick={handleExportCsvClick} />;
};

export default ExportToCsv;
