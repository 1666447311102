import { ALLOWED_COLLAPSED_EDGES_NODE_TYPES } from '../LineageExplore.constants';
import { InputNodesById } from '../LineageExplore.types';
import { cloneLineageData } from '../LineageExplore.utils';
import { getAllInputNodeParents } from '../useColumnLevelLineage/useColumnLevelLineage.utils';
import { EdgesById, NodesById } from '../useCreateNodesEdges/algorithm/types';
import recursiveParsing from '../useCreateNodesEdges/utils/traversal';

interface RemoveNodesParams {
  direction: 'upstream' | 'downstream';
  isColumnLevelLineage: boolean;
  nodeKey: string;
  nodesById: InputNodesById;
}

export const removeNodes = ({
  direction,
  isColumnLevelLineage,
  nodeKey,
  nodesById,
}: RemoveNodesParams) => {
  const clonedNodes = cloneLineageData(nodesById);

  // Remove the edges so that we won't find the downstream/upstream nodes in the recursive parsing
  if (direction === 'downstream') {
    clonedNodes[nodeKey].target_edges = {};
  } else {
    clonedNodes[nodeKey].source_edges = {};
  }

  const finalNodes = {} as InputNodesById;

  recursiveParsing({
    action: ({ id }) => {
      const node = clonedNodes[id];
      finalNodes[id] = node;

      const allParents = getAllInputNodeParents(id, clonedNodes);

      Object.keys(allParents).forEach((key) => {
        const clonedNode = clonedNodes[key];

        if (clonedNode.node_type === 'table') {
          (clonedNode.columns ?? []).forEach((columnKey) => {
            finalNodes[columnKey] = clonedNodes[columnKey];
          });
        }

        finalNodes[key] = clonedNode;
      });

      (node.columns ?? []).forEach((columnKey) => {
        finalNodes[columnKey] = clonedNodes[columnKey];
      });
    },
    initialNodeId: nodeKey,
    nodesById: clonedNodes,
    skipColumns: !isColumnLevelLineage,
  });

  /*
   * Add the edges back again because we still need them to have the borderline edges.
   * Reset the hideDownstreamButton/hideUpstreamButton since we are closing the downstream/upstream.
   */
  if (direction === 'downstream') {
    finalNodes[nodeKey].target_edges = nodesById[nodeKey].target_edges ?? {};
    finalNodes[nodeKey].hideDownstreamButton = false;
  } else {
    finalNodes[nodeKey].source_edges = nodesById[nodeKey].source_edges ?? {};
    finalNodes[nodeKey].hideUpstreamButton = false;
  }

  return finalNodes;
};

interface MergeRawNodesRelationsParams {
  newNodes: InputNodesById;
  pivotNodeKey: string;
  previousNodes: InputNodesById;
  shouldRemoveUnrelatedNodes: boolean;
}

/**
 * Used to merge the previous API nodes relations with the new ones after opening downstream/upstream
 * @param previousNodes previous API nodes before a downstream/upstream operation
 * @param newNodes new API nodes after requesting a downstream/upstream operation
 * @returns the new API nodes object containing the previous and new nodes relations
 */
export const mergeRawNodesRelations = ({
  newNodes,
  pivotNodeKey,
  previousNodes,
  shouldRemoveUnrelatedNodes,
}: MergeRawNodesRelationsParams) => {
  if (Object.keys(newNodes).length === 0) return previousNodes;

  const mergedNodes: InputNodesById = {};
  const finalNodes: InputNodesById = {};

  Object.entries(newNodes).forEach(([key, value]) => {
    if (previousNodes[key]) {
      mergedNodes[key] = {
        ...value,
        hideDownstreamButton: previousNodes[key].hideDownstreamButton,
        hideUpstreamButton: previousNodes[key].hideUpstreamButton,
        source_edges: { ...(previousNodes[key].source_edges ?? {}), ...(value.source_edges ?? {}) },
        target_edges: { ...(previousNodes[key].target_edges ?? {}), ...(value.target_edges ?? {}) },
      };
    } else {
      mergedNodes[key] = value;
    }
  });

  Object.entries(previousNodes).forEach(([key, value]) => {
    if (!mergedNodes[key]) {
      mergedNodes[key] = value;
    }
  });

  if (!shouldRemoveUnrelatedNodes) {
    return mergedNodes;
  }

  recursiveParsing({
    action: ({ id }) => {
      const node = mergedNodes[id];
      finalNodes[id] = node;
      const allParents = getAllInputNodeParents(id, mergedNodes);

      Object.keys(allParents).forEach((key) => {
        finalNodes[key] = mergedNodes[key];
      });

      (node.columns ?? []).forEach((columnKey) => {
        finalNodes[columnKey] = mergedNodes[columnKey];
      });
    },
    calculateStraightPath: true,
    initialNodeId: pivotNodeKey,
    nodesById: mergedNodes,
  });

  return finalNodes;
};

interface MergeCalculatedNodesDataProps {
  isClosing?: boolean;
  newEdges: EdgesById;
  newNodes: NodesById;
  previousEdges: EdgesById;
  previousNodes: NodesById;
  shouldRemoveUnrelatedNodes: boolean;
}

/**
 * Used to keep the same state for nodes that are on screen after opening downstream/upstream
 * @param previousNodes previous nodes before a downstream/upstream operation
 * @param newNodes new calculated nodes after a downstream/upstream operation
 * @param isClosing whether the operation is to close or expand the lineage
 * @returns the new nodes object with the same state as the previous ones
 */
export const mergeCalculatedNodesData = ({
  isClosing = false,
  newEdges,
  newNodes,
  previousEdges,
  previousNodes,
  shouldRemoveUnrelatedNodes,
}: MergeCalculatedNodesDataProps) => {
  const nodesById: NodesById = {};
  const edgesById: EdgesById = { ...newEdges };

  Object.entries(newNodes).forEach(([key, value]) => {
    const currentPreviousNode = previousNodes[key];

    if (currentPreviousNode) {
      nodesById[key] = {
        ...value,
        data: {
          ...value.data,
          childrenSearchText: currentPreviousNode?.data?.childrenSearchText,
          hiddenChildrenCount: currentPreviousNode?.data?.hiddenChildrenCount,
          hideDownstreamButton: currentPreviousNode?.data?.hideDownstreamButton,
          hideUpstreamButton: currentPreviousNode?.data?.hideUpstreamButton,
          highlightedText: currentPreviousNode?.data?.highlightedText,
          isOpen: currentPreviousNode?.data?.isOpen,
          isSearchEnabled: currentPreviousNode?.data?.isSearchEnabled,
          noMatchedChildren: currentPreviousNode?.data?.noMatchedChildren,
          shownChildrenCount: currentPreviousNode?.data?.shownChildrenCount,
        },
      };

      const areCollapsedEdgesAllowed = ALLOWED_COLLAPSED_EDGES_NODE_TYPES.includes(
        currentPreviousNode.type ?? '',
      );

      const relatedEdgesKeys = Object.keys(previousEdges).filter((edgeKey) =>
        edgeKey.includes(key),
      );

      relatedEdgesKeys.forEach((edgeKey) => {
        if (!edgesById[edgeKey] && !areCollapsedEdgesAllowed) {
          edgesById[edgeKey] = previousEdges[edgeKey];
        }
      });

      if (areCollapsedEdgesAllowed && nodesById[key].data.isOpen) {
        const newEdgesKeys = Object.keys(edgesById);
        const redirectedEdges = newEdgesKeys.filter((newEdge) => newEdge.includes(key));

        redirectedEdges.forEach((edgeKey) => {
          delete edgesById[edgeKey];
        });
      }
    } else {
      nodesById[key] = value;
    }
  });

  if (!isClosing && !shouldRemoveUnrelatedNodes) {
    Object.entries(previousNodes).forEach(([key, value]) => {
      if (nodesById[key]) return;
      nodesById[key] = value;
    });

    Object.entries(previousEdges).forEach(([key, value]) => {
      if (edgesById[key]) return;
      edgesById[key] = value;
    });
  }

  return { edgesById, nodesById };
};
