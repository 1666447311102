import React from 'react';
import * as CSS from 'csstype';
import type { UseComboboxGetInputPropsOptions } from 'downshift';

import Box from '@components/Box';
import { HelperText, type StyledInputProps } from '@components/Input/Input.v1.styles';
import useClickOutside from '@hooks/useClickOutside';

import CaretButton from '../CaretButton';
import ClearSelectButton from '../ClearSelectButton';
import MainInput, { MainInputProps } from '../MainInput/MainInput';
import OptionsList from '../OptionsList';
import { OptionsListProps } from '../OptionsList/OptionsList';
import type { Option as OptionType, RenderCustomAnchorArgs } from '../types';
import type { UseSelectProps } from '../useSelect';
import useSelect from '../useSelect';

import { StyledSelect } from './Select.styles';

type SelectOptionsListUnion =
  | 'expandedKeys'
  | 'isTree'
  | 'maxOptionsVisible'
  | 'options'
  | 'optionsListFooterElement'
  | 'searchPlaceholder'
  | 'showClearSelection'
  | 'showSelectAllButton'
  | 'wrapOptionText'
  | 'showSelectAllCount'
  | 'renderEmptyMessage'
  | 'renderCustomOption'
  | 'renderCustomContainer'
  | 'optionsListHeaderElement';
type SelectMainInputUnion =
  | 'isCreatable'
  | 'leftIcon'
  | 'multiOptionsType'
  | 'placeholder'
  | 'onNewOption';

export interface SelectProps
  extends Partial<Omit<StyledInputProps, 'status' | 'state' | 'zIndex'>>,
    Pick<UseSelectProps, 'containerId'>,
    Pick<OptionsListProps, SelectOptionsListUnion>,
    Pick<MainInputProps, SelectMainInputUnion>,
    Omit<UseSelectProps, 'closeOnBlur'> {
  fixedClearButton?: boolean;
  getClickOutsideExcludedTargets?: () => NodeListOf<Element> | HTMLElement[] | null | undefined;
  helperText?: string;
  hideCaret?: boolean;
  inputId?: string;
  inputProps?: UseComboboxGetInputPropsOptions;
  label?: string;
  loadingPosition?: 'input' | 'options-list';
  newOptionValidator?: (newOption: OptionType) => boolean;
  optionListMaxHeight?: CSS.Property.MaxHeight;
  renderCustomAnchor?: (args: RenderCustomAnchorArgs) => React.ReactNode;
  showClearButton?: boolean;
  showOptionsOnSearch?: boolean;
  showSearchOnOptions?: boolean;
}

const Select: React.FC<SelectProps> = ({
  clearInputOnBlur,
  clearInputOnSelect = true,
  containerId,
  disableFiltering,
  error,
  expandedKeys,
  fixedClearButton,
  getClickOutsideExcludedTargets,
  helperText,
  hideCaret = false,
  initialIsOpen,
  inputId,
  inputProps: customInputProps,
  isCreatable = false,
  isDisabled = false,
  isDropdown = false,
  isLoading = false,
  isMulti = false,
  isOpen: propIsOpen,
  isTree = false,
  label,
  leftIcon,
  loadingPosition = 'input',
  maxOptionsVisible,
  multiOptionsType,
  newOptionValidator,
  onChange,
  onClose,
  onInputBlur,
  onNewOption,
  onSearchValueChange,
  optionListMaxHeight,
  options = [],
  optionsFitAnchorWidth,
  optionsListFooterElement,
  optionsListHeaderElement,
  placeholder = 'Select',
  popperConfigProps,
  renderCustomAnchor,
  renderCustomContainer,
  renderCustomOption,
  renderEmptyMessage,
  searchPlaceholder = 'Search',
  searchValue,
  setIsOpen,
  shouldBlockOnLoading = true,
  showClearButton = false,
  showClearSelection = false,
  showOptionsOnSearch,
  showSearchOnOptions = false,
  showSelectAllButton = true,
  showSelectAllCount = true,
  value,
  wrapOptionText = true,
  ...other
}) => {
  const {
    addSelectedItem,
    closeMenu,
    dropdownProps,
    fieldState,
    filteredItems,
    flatOptions,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    inputValue,
    isAllSelected,
    isOpen,
    popper: { anchorProps, popperProps },
    removeSelectedItem,
    resetSelect,
    selectedItems,
    setInputValue,
    setSelectedItems,
    updateFlatOptions,
  } = useSelect({
    clearInputOnBlur,
    clearInputOnSelect,
    closeOnBlur: !getClickOutsideExcludedTargets,
    containerId,
    disableFiltering,
    error,
    expandedKeys,
    initialIsOpen,
    isDisabled,
    isDropdown,
    isLoading,
    isMulti,
    isOpen: propIsOpen,
    isTree,
    onChange,
    onClose,
    onInputBlur,
    onSearchValueChange,
    options,
    optionsFitAnchorWidth,
    popperConfigProps,
    searchValue,
    setIsOpen,
    shouldBlockOnLoading,
    value,
  });

  const selectAnchorProps = getToggleButtonProps({
    ...dropdownProps,
    ...anchorProps,
  });

  const toggleButtonProps = getToggleButtonProps({
    ...dropdownProps,
  });

  const menuProps = getMenuProps(popperProps);

  const inputProps = getInputProps({
    ...dropdownProps,
    autoFocus: isOpen,
    ...customInputProps,
  });

  const useClickOutsideRef = useClickOutside<HTMLDivElement>({
    disabled: !getClickOutsideExcludedTargets && !isOpen,
    excludedTargets: getClickOutsideExcludedTargets,
    ignoreElements: ['select'],
    onClick: () => {
      closeMenu?.();
    },
  });

  const isMultiString = isMulti && multiOptionsType === 'string';

  return (
    <>
      {renderCustomAnchor?.({
        anchorProps: selectAnchorProps,
        anchorRef: useClickOutsideRef,
        isOpen,
        selectedItem: selectedItems,
      }) ?? (
        <Box compDisplay="flex" compWidth="100%" flexDirection="column" noDefault>
          <StyledSelect
            aria-disabled={isDisabled}
            aria-label={label}
            cursor={isDropdown ? 'pointer' : undefined}
            hideFocusState={fieldState !== 'error'}
            isMultiString={isMultiString}
            role="button"
            state={fieldState}
            {...selectAnchorProps}
            {...other}
          >
            <MainInput
              addSelectedItem={addSelectedItem}
              fieldState={fieldState}
              id={inputId}
              inputProps={inputProps}
              isCreatable={isCreatable}
              isDropdown={isDropdown}
              isLoading={isLoading && loadingPosition === 'input' && shouldBlockOnLoading}
              isMulti={isMulti}
              leftIcon={leftIcon}
              multiOptionsType={multiOptionsType}
              newOptionValidator={newOptionValidator}
              onNewOption={onNewOption}
              options={options}
              placeholder={placeholder}
              removeSelectedItem={removeSelectedItem}
              selectedItems={selectedItems}
              setInputValue={setInputValue}
              showSearch={showSearchOnOptions}
              value={inputValue}
            />
            {showClearButton &&
              ((selectedItems.length > 0 && !isLoading) ||
                (fixedClearButton && inputValue.length > 0)) && (
                <ClearSelectButton onClick={resetSelect} />
              )}
            {!hideCaret && (
              <CaretButton
                isLoading={isLoading && loadingPosition === 'input'}
                isOpen={isOpen}
                {...toggleButtonProps}
              />
            )}
          </StyledSelect>
          {helperText && <HelperText state={fieldState}>{helperText}</HelperText>}
        </Box>
      )}
      <OptionsList
        expandedKeys={expandedKeys}
        filteredItems={filteredItems}
        flatOptions={flatOptions}
        getItemProps={({ index, item }) =>
          getItemProps({
            index,
            item,
          })
        }
        highlightedIndex={highlightedIndex}
        inputProps={inputProps}
        inputValue={inputValue}
        isAllSelected={isAllSelected}
        isLoading={isLoading && loadingPosition === 'options-list'}
        isMulti={isMulti}
        isOpen={isOpen && showOptionsOnSearch ? !!inputValue : isOpen}
        isTree={isTree}
        maxHeight={optionListMaxHeight}
        maxOptionsVisible={maxOptionsVisible}
        menuProps={menuProps}
        options={options}
        optionsListFooterElement={optionsListFooterElement}
        optionsListHeaderElement={optionsListHeaderElement}
        renderCustomContainer={renderCustomContainer}
        renderCustomOption={renderCustomOption}
        renderEmptyMessage={renderEmptyMessage}
        resetSelect={resetSelect}
        searchPlaceholder={searchPlaceholder}
        selectedItems={selectedItems}
        setInputValue={setInputValue}
        setSelectedItems={setSelectedItems}
        showClearSelection={showClearSelection}
        showSearch={showSearchOnOptions || isMultiString}
        showSelectAllButton={showSelectAllButton}
        showSelectAllCount={showSelectAllCount}
        updateFlatOptions={updateFlatOptions}
        wrapOptionText={wrapOptionText}
      />
    </>
  );
};

export default Select;
