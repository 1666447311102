import styled from '@emotion/styled';

import Box from '@components/Box';
import Resizable from '@components/Resizable';
import Text from '@components/Text';

interface StyledSecondarySidebarProps {
  isVisible: boolean;
}

export const StyledSecondarySidebar = styled(Resizable)<StyledSecondarySidebarProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-right: 1px solid ${({ theme }) => theme.colors.gray[200]};
  box-shadow: ${({ theme }) => theme.shadow.md};
  display: flex;
  flex-direction: column;
  height: 100%;
  transform: translateX(${({ isVisible }) => (isVisible ? '0%' : '-105%')});
  position: ${({ isVisible }) => (isVisible ? 'relative' : 'absolute !important')};
  ${({ theme }) => theme.media.down('sm')`
    max-width: 200px !important;
  `}
  padding-top: ${({ theme }) => theme.space(2)};
  gap: ${({ theme }) => theme.space(3)};
`;

export const StyledSecondarySidebarTitle = styled(Text)``;

StyledSecondarySidebarTitle.defaultProps = {
  color: 'gray.800',
  fontWeight: 'medium',
  lineHeight: '24px',
  m: 0,
  px: 1.5,
};

export const StyledSecondarySidebarContent = styled(Box)``;

StyledSecondarySidebarContent.defaultProps = {
  compDisplay: 'flex',
  flex: 1,
  flexDirection: 'column',
  overflow: 'auto',
};
