import React, {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RouterLink, useHistory } from '@routing/router';
import { Search, SearchCategoryProps, SearchResultData } from 'semantic-ui-react';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

import { useFetchActivity } from '@api/activity';
import { searchCacheKeys, useFetchSearch, useFetchSearchSuggestions } from '@api/search';
import { SearchModel } from '@api/search/SearchModel';
import type { SearchResult as SearchResultType } from '@api/search/types';
import CircularLoader from '@components/CircularLoader';
import Text from '@components/Text';
import getUrl from '@components/Tree/getUrl';
import Icon from '@components/UI/Icon';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { useUserContext } from '@context/User';
import useClickOutside from '@hooks/useClickOutside';
import fetchClient from '@lib/fetchClient';
import { MetadataData } from '@models/MetadataModel';
import stripSpaces from '@utils/stripSpaces';

import DatasourceTabs from './DatasourceTabs/DatasourceTabs';
import type { DatasourceTab } from './DatasourceTabs/DatasourceTabs.types';
import {
  StyledBackdrop,
  StyledGradient,
  StyledSearchBar,
  StyledSearchBarResultsWrapper,
  StyledSearchTabsScrollContainer,
} from './SearchBar.styles';
import { defaultSearchContext, SearchContext } from './SearchContext';
import SearchFilter from './SearchFilter';
import SearchResult from './SearchResult';
import AboveSearchContainer from './SearchResult/AboveSearchContainer.styles';
import HistoryItem from './SearchResult/HistoryItem';
import { SearchResultContainer } from './SearchResult/SearchResult.styles';
import SearchSuggestionItem from './SearchResult/SearchSuggestionItem';
import { SearchFiltersType } from './SearchTypes';

let searchText = '';

export const PLACEHOLDER_TEXT = 'Search for datasets, dashboards, or users';

const DEFAULT_ACTIVE_TAB = {
  filters: defaultSearchContext.filters,
  name: 'All',
};

const emptyResult: SearchModel[] = [];

const query = stripSpaces(`{
	guid,
  target {
    object_type,
    obj {
      tagged_items {
        tag
      },
      -downstream_objects_counts,
      -upstream_objects_counts
    }
  }
}`);

const defaultSearchParams = {
  no_popularity: false,
  tags_search: true,
};

const iconRender = (isShow: boolean, loading: boolean) => {
  if (isShow && loading) {
    return (
      <div className="search-icon">
        <CircularLoader
          bgColor="rgba(255, 255, 255, 0.2)"
          borderWidth={2}
          color="white"
          compSize={1.5}
        />
      </div>
    );
  }

  return (
    <div className="search-icon">
      <Icon color="white" ml={-0.3} name="search" size="22px" />
    </div>
  );
};

interface ResultRendererProps extends SearchModel {
  searchSuggestions?: string[];
}

const ResultRenderer: React.FC<ResultRendererProps> = (props) => {
  const { id, routerUrl, searchSuggestions } = props;
  return (
    <SearchResultContainer key={id} as={RouterLink} to={routerUrl}>
      <SearchResult
        searchItem={props}
        searchSuggestions={searchSuggestions}
        searchText={searchText}
      />
    </SearchResultContainer>
  );
};

const HistoryResultRender: any = (searchItem: MetadataData): ReactElement<any> => {
  const { guid, routePath, routerUrl } = searchItem;
  return (
    <SearchResultContainer
      key={guid}
      as={RouterLink}
      to={routePath ?? routerUrl ?? getUrl({ ...searchItem, showSchemataPage: true })}
    >
      <HistoryItem historyItem={searchItem} />
    </SearchResultContainer>
  );
};

const CategoryLayoutRenderer = ({
  categoryContent,
  resultsContent,
}: SearchCategoryProps): React.ReactElement<any> => (
  <>
    {categoryContent}
    <StyledSearchBarResultsWrapper>{resultsContent}</StyledSearchBarResultsWrapper>
  </>
);

const filtersToString = (filters: SearchFiltersType): string =>
  Object.keys(filters)
    .filter((f) => filters[f as keyof SearchFiltersType])
    .join(',');

const SearchBar: React.FC = () => {
  const history = useHistory();
  const { organization } = useUserContext();
  const [text, setText] = useState<string>('');
  const [requestId, setRequestId] = useState<string | undefined>();
  const [textDebounced] = useDebounce(text, 500);
  const [facets, setFacets] = useState<SearchResultType<SearchModel>['facets']>();
  const [isShow, setIsShow] = useState<boolean>(false);
  const [filters, setFilters] = useState<SearchFiltersType>(defaultSearchContext.filters);
  const allowSearchPageOnEnterRef = useRef(true);
  const segment = useSegmentContext();
  const clickOutsideRef = useClickOutside<HTMLDivElement>({ onClick: () => setIsShow(false) });
  const [activeTab, setActiveTab] = useState<DatasourceTab>(DEFAULT_ACTIVE_TAB);
  const shouldUpdateFacets = useRef(true);

  const searchParams = {
    ...defaultSearchParams,
    custom_attributes_search: organization?.settings?.useCustomAttributesSearch,
    include: filtersToString(filters),
    q: textDebounced,
  };

  const { data: activityData } = useFetchActivity({
    params: {
      distinct: true,
      order: '-performed_on',
      page_size: 10,
      query,
      types: 'view',
      users: 'me',
    },
  });

  const { data: normalData, isFetching: isFetchingNormalData } = useFetchSearch({
    enabled: Boolean(searchParams.q),
    keepPreviousData: true,
    onSuccess: ({ requestId: internalRequestId }) => {
      setRequestId(internalRequestId);
    },
    params: {
      no_fuzzy: true,
      ...searchParams,
    },
  });

  const { data: fuzzyData, isFetching: isFetchingFuzzyData } = useFetchSearch({
    enabled: !isFetchingNormalData && normalData?.total === 0 && Boolean(searchParams.q),
    keepPreviousData: true,
    onSuccess: ({ requestId: internalRequestId }) => {
      setRequestId(internalRequestId);
    },
    params: {
      no_fuzzy: false,
      ...searchParams,
    },
  });

  const { data: suggestionsData } = useFetchSearchSuggestions({
    enabled: !isFetchingNormalData && normalData?.total === 0 && Boolean(searchParams.q),
    params: {
      q: searchParams.q,
    },
  });

  const activities = activityData?.results
    ?.map((item) => item?.target?.obj)
    .filter((item) => item.name && item.name.toLowerCase() !== 'deleted item');
  const data = useMemo(
    () => (normalData && normalData?.total > 0 ? normalData : fuzzyData),
    [fuzzyData, normalData],
  );
  const isFetchingData = isFetchingNormalData || isFetchingFuzzyData;
  const showActivities = !data && !textDebounced;
  const showFuzzySearchSuggestion =
    Boolean(textDebounced) &&
    (!normalData || normalData?.total === 0) &&
    suggestionsData &&
    suggestionsData?.texts?.length > 0;

  useEffect(() => {
    shouldUpdateFacets.current = false;
    setFilters(activeTab.filters);
  }, [activeTab]);

  useEffect(() => {
    shouldUpdateFacets.current = true;
    setFilters(defaultSearchContext.filters);
    setActiveTab(DEFAULT_ACTIVE_TAB);
  }, [textDebounced]);

  useEffect(() => {
    if (shouldUpdateFacets.current && !isFetchingData) {
      setFacets(data?.facets);
      shouldUpdateFacets.current = false;
    }
  }, [data, isFetchingData]);

  const resetSearch = () => {
    setText('');
    setIsShow(false);
    setActiveTab(DEFAULT_ACTIVE_TAB);
    shouldUpdateFacets.current = true;
    setFilters(defaultSearchContext.filters);
    searchText = '';
  };

  const handleMouseDown = () => {
    setIsShow(true);
  };

  const openUrl = (url: string, isExternal: boolean = false) => {
    if (isExternal) {
      window.open(url, '_blank');
      return;
    }
    history.push({ pathname: url });
  };

  const trackSelectedValue = (result: SearchModel) => {
    const { guid, indexPosition } = result;

    if (indexPosition !== undefined) {
      segment?.track(SegmentTrackEventName.RegularSearchResultClicked, {
        guid,
        rank: indexPosition,
        tab: activeTab?.name,
        uuid: requestId,
      });
    }
  };

  const handleResultSelect = <T extends SearchModel & MetadataData>(
    e: MouseEvent | KeyboardEvent,
    { result }: { result: T },
  ): void => {
    trackSelectedValue(result);
    const route = result.routerUrl ?? getUrl({ ...result, showSchemataPage: true });
    const name = showActivities ? result.name : result.title;
    const isExternal = e.ctrlKey || e.metaKey;

    if (!isExternal) {
      setText(name || 'unknown');
      resetSearch();
    }

    if ((e as KeyboardEvent).key === 'Enter') {
      openUrl(route!, isExternal);
    }
  };

  const invalidateSearchResultsDebounced = useDebouncedCallback(() => {
    fetchClient.invalidateQueries(searchCacheKeys.recentSearches);
  }, 1000);

  const handleSearchChange = (e: React.MouseEvent<HTMLElement>, { value }: any): void => {
    setText(value);
    setIsShow(true);
    allowSearchPageOnEnterRef.current = true;

    if (value.length < 1) {
      resetSearch();
    }

    if (value) {
      invalidateSearchResultsDebounced();
    }

    searchText = value;
  };

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      // Send to search results page on enter if we haven't already selected a result.
      if (e.key === 'Enter' && allowSearchPageOnEnterRef.current) {
        // Send to search results page on enter if we haven't already selected a result.
        const fuzzySearchParam = showFuzzySearchSuggestion
          ? `&fuzzy=${showFuzzySearchSuggestion}`
          : '';
        segment?.track(SegmentTrackEventName.FullSearchPageOpened, {
          fuzzy: showFuzzySearchSuggestion,
          query: text,
        });
        history.push(`/search?q=${encodeURIComponent(text)}${fuzzySearchParam}`);
        resetSearch();
      }
    },
    [history, showFuzzySearchSuggestion, text, segment],
  );

  // Override default cmd + f and ctrl + f behavior for browsers and instead open table filters if they exist.
  useEffect(() => {
    const handlePressCtrlK = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        setIsShow(true);
      }
    };

    window.addEventListener('keydown', handlePressCtrlK);
    return () => {
      window.removeEventListener('keydown', handlePressCtrlK);
    };
  }, []);

  const handleSelectionChange = (e: React.SyntheticEvent, d: SearchResultData) => {
    // If we've selected a result and it's not a filter result send to result url instead of search results page on enter.
    allowSearchPageOnEnterRef.current = !d?.result?.guid;
  };

  const results = useMemo(() => {
    if (showActivities) return !activities ? emptyResult : activities;

    return data?.data ?? emptyResult;
  }, [activities, data, showActivities]);

  const getResultsRenderer = (props: any) => {
    if (showActivities) {
      return <HistoryResultRender {...props} />;
    }
    return <ResultRenderer {...props} searchSuggestions={suggestionsData?.texts} />;
  };

  const aboveResults = useMemo(() => {
    const noResultsTemplate = !isFetchingData && data?.total === 0 && Boolean(text) && (
      <Text color="inherit" fontWeight="bold" mb={0} p={2.5}>
        No results found.
      </Text>
    );

    const above = (
      <>
        <StyledGradient>
          <StyledSearchTabsScrollContainer>
            <DatasourceTabs
              activeTab={activeTab}
              data={data}
              facets={facets}
              onKeyDown={handleKeyDown}
              setActiveTab={setActiveTab}
              showResultCount
            />
          </StyledSearchTabsScrollContainer>
        </StyledGradient>
        <SearchFilter activeTab={activeTab} searchResult={data} setFilters={setFilters} />
        {noResultsTemplate}
      </>
    );

    if (showFuzzySearchSuggestion) {
      return (
        <>
          <SearchSuggestionItem searchSuggestionTexts={suggestionsData?.texts} text={text} />
          {above}
        </>
      );
    }

    if (data && Boolean(text)) {
      return above;
    }

    if (showActivities && activities && activities.length > 0) {
      return <AboveSearchContainer>Recently Viewed</AboveSearchContainer>;
    }

    return null;
  }, [
    activeTab,
    activities,
    data,
    handleKeyDown,
    isFetchingData,
    showActivities,
    showFuzzySearchSuggestion,
    suggestionsData?.texts,
    text,
  ]);

  const context = useMemo(() => {
    return {
      facets: data?.facets ?? defaultSearchContext.facets,
      filters,
      setFilters: (f: SearchFiltersType) => setFilters(f),
      shown: data?.shown ?? 0,
      total: data?.total ?? 0,
    };
  }, [filters, data?.facets, data?.shown, data?.total]);

  return (
    <SearchContext.Provider value={context}>
      {isShow && process.env.REACT_APP_CHROME_EXTENSION_BUILD && <StyledBackdrop />}
      <StyledSearchBar ref={clickOutsideRef} className="topsearch">
        <Search
          category
          categoryLayoutRenderer={CategoryLayoutRenderer}
          className={isShow ? 'with-results' : undefined}
          icon={iconRender(isShow, isFetchingData)}
          loading={isFetchingData}
          onKeyDown={handleKeyDown}
          onMouseDown={handleMouseDown}
          onResultSelect={handleResultSelect}
          onSearchChange={handleSearchChange}
          onSelectionChange={handleSelectionChange}
          open={isShow}
          placeholder={PLACEHOLDER_TEXT}
          resultRenderer={getResultsRenderer}
          results={{ category: { name: aboveResults, results } }}
          value={text}
        />
        {isShow && (
          <>
            <Icon className="search-clear" name="close" onClick={() => resetSearch()} size="14px" />
            <div
              aria-label="Save"
              className="search-overlay"
              onClick={() => setIsShow(false)}
              onKeyDown={() => setIsShow(false)}
              role="button"
              tabIndex={-1}
            />
          </>
        )}
      </StyledSearchBar>
    </SearchContext.Provider>
  );
};

export default SearchBar;
