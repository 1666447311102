import React, { useEffect } from 'react';
import { dataSources } from '@configs/dataSources/config';
import copy from 'copy-to-clipboard';

import { usePostOpenLineageAuth } from '@api/openLineage';
import Alert from '@components/Alert';
import Box from '@components/Box';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import PreviousStepButton from '@components/DataSourceSetup/components/Buttons/PreviousStepButton';
import DataSourceAddStepError from '@components/DataSourceSetup/components/DataSourceAddStep/DataSourceAddStepError';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import type { StepProps } from '@components/DataSourceSetup/types';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import IconButton from '@components/IconButton';
import Input from '@components/Input/Input.v1';
import Link from '@components/Link';
import { renderInfoToast } from '@components/Toast';
import Icon from '@components/UI/Icon';
import { ModalFooter } from '@components/UI/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

enum FieldKey {
  apiToken = 'api_token',
  eventsEndpoint = 'events_endpoint',
}

interface FormValues {
  [FieldKey.apiToken]?: string;
  [FieldKey.eventsEndpoint]?: string;
}

const REQUIRED_FIELDS = [FieldKey.apiToken, FieldKey.eventsEndpoint];

const AirflowConfigureStepForm: React.FC<StepProps> = ({
  dataSource,
  onDataSourceAdded,
  prevStep,
}) => {
  const segment = useSegmentContext();

  const { handleSubmit, setValues, values } = useForm<FormValues>({
    initialValues: {
      [FieldKey.apiToken]: '',
      [FieldKey.eventsEndpoint]: '',
    },
    onSubmit: () => {
      onDataSourceAdded(dataSource);
      segment?.track(SegmentTrackEventName.ImportData_ConfigureNextButtonClicked, {
        dataType: dataSources.airflow.value,
      });
    },
  });

  const { error, isLoading, mutate } = usePostOpenLineageAuth({
    onSuccess: (res) => {
      setValues({
        [FieldKey.apiToken]: res?.apiToken,
        [FieldKey.eventsEndpoint]: res?.eventsEndpoint,
      });
    },
  });

  const INPUT_CONFIG = {
    [FieldKey.apiToken]: {
      key: FieldKey.apiToken,
      label: 'API Token',
    },
    [FieldKey.eventsEndpoint]: {
      key: FieldKey.eventsEndpoint,
      label: 'Events Endpoint',
    },
  };

  const isInvalid = REQUIRED_FIELDS.some((key) => Boolean(values[key]) === false);

  useEffect(() => {
    mutate({ data_source_guid: dataSource?.guid });
  }, [dataSource?.guid, mutate]);

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        <Box gridColumn="1/3">
          <Alert type="info">
            For more information on how to use these credentials, visit{' '}
            <Link
              fontSize="inherit"
              href="https://docs.selectstar.com/integrations"
              rel="noopener noreferrer"
              target="_blank"
              underline
            >
              how to configure
            </Link>
          </Alert>
        </Box>
        {Object.values(INPUT_CONFIG).map(({ key, label, ...other }) => {
          const value = values[key];
          const disabled = !value;
          return (
            <StyledLabel key={key}>
              {label}
              <Input
                disabled={disabled}
                endIcon={
                  <IconButton
                    disabled={disabled}
                    ml={0.5}
                    onClick={() => {
                      copy(value!);
                      renderInfoToast('Copied to clipboard');
                    }}
                  >
                    <Icon name="copy" />
                  </IconButton>
                }
                name={key}
                placeholder={label}
                readOnly
                value={value}
                {...other}
              />
            </StyledLabel>
          );
        })}
      </StyledFormHorizontalLabelGrid>
      <DataSourceAddStepError error={error} mb={3} />
      <ModalFooter>
        <PreviousStepButton
          disabled={isLoading}
          onClick={() => {
            segment?.track(SegmentTrackEventName.ImportData_ConfigurePrevButtonClicked, {
              dataType: dataSources.airflow.value,
            });
            prevStep();
          }}
        />
        <NextStepButton disabled={isLoading || isInvalid} text="Continue" />
      </ModalFooter>
    </Form>
  );
};

export default AirflowConfigureStepForm;
