import React, { useEffect, useState } from 'react';
import { RouterLink, useHistory, useLocation } from '@routing/router';

import { LogoImg, LogoLink } from '@components/AppHeader/AppHeader.styles';
import Box from '@components/Box';
import Hidden from '@components/Hidden';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';

import { SidebarItemIcons, SidebarSection } from '../AppMainSidebar.types';

import {
  PRIMARY_SIDEBAR_TRANSITION_DURATION,
  StyledPrimarySidebar,
  StyledPrimarySidebarLink,
  StyledPrimarySidebarLinksSection,
  StyledPrimarySidebarLogoContainer,
} from './PrimarySidebar.styles';

interface PrimarySidebarProps {
  activeMenuItemId: string;
  disableAccessToHomepage?: boolean;
  isCollapsed: boolean;
  onHomeClick?: () => void;
  sections?: SidebarSection[];
  setActiveMenuItemId: (id: string) => void;
}

const getItemIcons = ({ active, default: defaultIcon }: SidebarItemIcons) => {
  const activeIconElement =
    typeof active === 'string' ? <Icon color="currentcolor" name={active} size="20px" /> : active;

  const defaultIconElement =
    typeof defaultIcon === 'string' ? (
      <Icon color="currentcolor" name={defaultIcon} size="20px" />
    ) : (
      defaultIcon
    );

  return { activeIcon: activeIconElement, defaultIcon: defaultIconElement };
};

const PrimarySidebar = ({
  activeMenuItemId,
  disableAccessToHomepage = false,
  isCollapsed,
  onHomeClick,
  sections = [],
  setActiveMenuItemId,
}: PrimarySidebarProps) => {
  const history = useHistory();
  const { dataSources } = useUserContext();
  const { pathname: currentPath } = useLocation();

  const [showSmallLogo, setShowSmallLogo] = useState(isCollapsed);

  useEffect(() => {
    if (isCollapsed) {
      setTimeout(() => {
        setShowSmallLogo(true);
      }, PRIMARY_SIDEBAR_TRANSITION_DURATION);
    } else {
      setShowSmallLogo(false);
    }
  }, [isCollapsed]);

  const smallLogo = (
    <LogoImg
      alt="Select Star logo small"
      animate={isCollapsed}
      compHeight="32px"
      src="/icons/logo-ss-sign.svg"
    />
  );

  const logoWithText = (
    <LogoImg alt="Select Star logo" compHeight="20px" src="/icons/logo-ss-horizontal-black.svg" />
  );

  return (
    <StyledPrimarySidebar
      aria-expanded={!isCollapsed}
      data-testid="primary-sidebar"
      isCollapsed={isCollapsed}
    >
      <StyledPrimarySidebarLogoContainer shouldCenterContent={showSmallLogo}>
        <LogoLink
          aria-disabled={disableAccessToHomepage}
          disableAccess={disableAccessToHomepage}
          onClick={onHomeClick}
          to="/"
        >
          {showSmallLogo ? (
            smallLogo
          ) : (
            <>
              <Hidden hideDown={['md']}>{logoWithText}</Hidden>
              <Hidden hideUp={['md']}>{smallLogo}</Hidden>
            </>
          )}
        </LogoLink>
      </StyledPrimarySidebarLogoContainer>
      <Box compDisplay="flex" compWidth="100%" flex={1} flexDirection="column">
        {sections.map(({ id, items }) => (
          <StyledPrimarySidebarLinksSection key={id}>
            {items.map(({ getTo, icons, id: itemId, label, onClick, tooltipText }) => {
              const to = getTo?.({ currentPath });
              const isActive = activeMenuItemId === itemId;

              const { activeIcon, defaultIcon } = getItemIcons(icons);

              return (
                <Tooltip
                  key={itemId}
                  content={isCollapsed ? tooltipText ?? label : ''}
                  followCursor={false}
                  placement="right"
                >
                  <StyledPrimarySidebarLink
                    as={to ? RouterLink : 'button'}
                    isActive={isActive}
                    isSidebarCollapsed={isCollapsed}
                    onClick={() => {
                      setActiveMenuItemId(itemId);
                      onClick?.({ dataSources, push: history.push });
                    }}
                    to={to}
                  >
                    {isActive ? activeIcon : defaultIcon}
                    {isCollapsed ? null : label}
                  </StyledPrimarySidebarLink>
                </Tooltip>
              );
            })}
          </StyledPrimarySidebarLinksSection>
        ))}
      </Box>
    </StyledPrimarySidebar>
  );
};

export default PrimarySidebar;
