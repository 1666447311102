import React, { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Message } from 'semantic-ui-react';
import { Descendant } from 'slate';

import { DescriptionSource } from '@api/description/description.types';
import { searchCacheKeys, usePostQuickSearch } from '@api/search';
import { defaultMentionFilters } from '@api/search/indexes';
import { SearchModel } from '@api/search/SearchModel';
import type { QuickSearchIndexFilter, SearchResult } from '@api/search/types';
import Box, { StyledBoxProps } from '@components/Box';
import Button from '@components/Button/Button';
import DescriptionSelector from '@components/DescriptionSelector';
import InlineUnexpectedError from '@components/Error/InlineUnexpectedError';
import { ShowMoreDescriptionButton } from '@components/ExcerptText/ExcerptText.styles';
import type { RichTextEditorProps } from '@components/RichTextEditor';
import convertStringToSlate from '@components/RichTextEditor/helpers/convertStringToSlate';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import { resetEditorValue } from '@components/RichTextEditor/helpers/resetEditorValue';
import {
  DEFAULT_SLATE_STATE,
  serializeSlateToPlainText,
} from '@components/RichTextEditor/helpers/serializationHelpers';
import useSlateEditor from '@components/RichTextEditor/Hooks/useSlateEditor';
import useTruncateSlate from '@components/RichTextEditor/Hooks/useTruncateSlate';
import truncateSlate from '@components/RichTextEditor/Hooks/useTruncateSlate/truncateSlate';
import useMentions from '@components/RichTextEditor/Mentions/useMentions';
import { RichTextEditorElement } from '@components/RichTextEditor/RichTextEditor.types';
import { DatasourceTabV1 } from '@components/SearchBar/DatasourceTabs/config';
import Text from '@components/Text';
import { StyledTextProps } from '@components/Text/Text';
import { renderInfoToast } from '@components/Toast';
import Card, { CardProps } from '@components/UI/Card';
import Icon from '@components/UI/Icon';
import { useFetchedMentions } from '@context/FetchedMentions';
import FetchedMention from '@context/FetchedMentions/FetchedMention';
import { useModal } from '@context/Modal';
import useClickOutside from '@hooks/useClickOutside';
import fetchClient from '@lib/fetchClient';
import { ValidDSType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { MetadataModel } from '@models/MetadataModel';
import theme from '@styles/theme';

import EditorNavigationBlocker from './EditorNavigationBlocker';
import {
  StyledEditIcon,
  StyledInlineRichTextEditorContent,
  StyledRichTextDescriptionEditor,
  StyledRichTextDescriptionEditorProps,
  StyledRichTextEditor,
} from './RichTextDescriptionEditor.styles';
import { isSuggestedDescription } from './RichTextDescriptionEditor.utils';
import useDescriptionOptions from './useDescriptionOptions';
import useMarkdownDescriptionEditor from './useMarkdownDescriptionEditor';
import { ConversionCache } from './useMarkdownDescriptionEditor/useMarkdownDescriptionEditor';

interface Descriptions {
  aiDescription?: string;
  description?: string;
  ingestedDescription?: string;
  richTextDescription?: string;
  suggestedDescription?: string;
  userDescription?: string;
}

export interface RichTextDescriptionEditorProps
  extends StyledRichTextDescriptionEditorProps,
    Partial<Pick<RichTextEditorProps, 'placeholder'>> {
  allowedElements?: RichTextEditorElement[];
  blockNavigation?: boolean;
  customMentionFilters?: QuickSearchIndexFilter[];
  customSearchTabs?: DatasourceTabV1[];
  dataSourceType?: ValidDSType;
  dataTypes?: DataTypesModel;
  descriptionSource?: DescriptionSource;
  descriptions: Descriptions;
  disableSaveOnEnter?: boolean;
  editMode?: boolean;
  forceEditModeOnStart?: boolean;
  guid?: string;
  isEditable?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  markdownEnabled?: boolean;
  maxHeight?: string;
  maxLength?: number;
  onDescriptionChange?: (description: string, plainTextDescription?: string) => void;
  onDescriptionSave?: (
    description: string,
    plainTextDescription?: string,
    descriptionSource?: DescriptionSource,
  ) => void;
  parentGuid?: string;
  searchTermsToHighlight?: string;
  shouldFocusOnEdit?: boolean;
  shouldStartFocused?: boolean;
  showDescriptionSelector?: boolean;
  showToolbar?: boolean;
  suggestedDescriptionSource?: string;
  suggestedDescriptionSourceObject?: MetadataModel;
  title?: string;
  titleFontSize?: StyledTextProps['fontSize'];
  toastTitle?: string;
  truncateDisabled?: boolean;
  wrapperProps?: CardProps | StyledBoxProps;
}

const WrapperVariants = {
  border: Card,
  default: Box,
};

const RichTextDescriptionEditor: React.FC<RichTextDescriptionEditorProps> = ({
  allowedElements,
  blockNavigation,
  customMentionFilters,
  customSearchTabs,
  dataSourceType,
  dataTypes,
  descriptionSource,
  descriptions,
  disableSaveOnEnter = false,
  editIconVariant = 'hover',
  editMode = false,
  fontSize,
  forceEditModeOnStart = false,
  guid,
  isEditable,
  isError,
  isLoading,
  isSuccess,
  markdownEnabled = true,
  maxHeight = 'unset',
  maxLength,
  onDescriptionChange,
  onDescriptionSave,
  parentGuid,
  placeholder,
  searchTermsToHighlight,
  shouldFocusOnEdit = true,
  shouldStartFocused = false,
  showDescriptionSelector,
  showToolbar,
  suggestedDescriptionSource,
  suggestedDescriptionSourceObject,
  title = 'Description',
  titleFontSize = 'h4',
  toastTitle,
  truncateDisabled = false,
  variant = 'inline',
  wrapperProps: customWrapperProps,
}) => {
  const { getMentionFromCacheById } = useFetchedMentions();
  const isBlockEditor = ['block', 'block-simple'].includes(variant);
  const isInlineEditor = variant === 'inline';
  const maxLengthValue = maxLength || (isBlockEditor ? undefined : 100);
  const {
    aiDescription,
    description,
    ingestedDescription,
    richTextDescription,
    suggestedDescription,
    userDescription,
  } = descriptions;
  const textValue = richTextDescription || description || suggestedDescription || '';
  const isSuggestion = isSuggestedDescription(descriptionSource);
  const [isEditing, setIsEditing] = useState(forceEditModeOnStart || editMode);
  const [isFocused, setIsFocused] = useState(shouldStartFocused);
  const [focusOnEdit, setFocusOnEdit] = useState(shouldFocusOnEdit);
  const [cancelState, setCancelState] = useState(textValue);
  const { MODAL_IDS, openModal } = useModal();
  const [showMarkdownEditor, setShowMarkdownEditor] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState({
    convertedRichText: '',
    currentMarkdown: '',
  });
  const [cache, setCache] = useState<{
    toMarkdown?: ConversionCache;
    toRichText?: ConversionCache;
  }>({
    toMarkdown: undefined,
    toRichText: undefined,
  });

  const [editor] = useSlateEditor();
  const initialSlateState = useMemo(() => convertStringToSlate(textValue), [textValue]);
  const [slateEditorState, setSlateEditorState] = useState<Descendant[]>(
    initialSlateState ?? DEFAULT_SLATE_STATE,
  );

  const shouldShowToolbar = showToolbar || isBlockEditor;
  const showEdit = !isEditing && isEditable;
  const showEditIcon = showEdit && editIconVariant === 'always' && isInlineEditor;

  const operationTypeRef = useRef<'save' | 'convert' | undefined>(undefined);

  const {
    handleShowMore,
    isShowButtonVisible,
    setShowMore,
    setTruncatedSlateState,
    shouldTruncate,
  } = useTruncateSlate(editor, slateEditorState, isEditing, maxLengthValue, truncateDisabled);

  const [mentionResults, setMentionResults] = useState<SearchResult<SearchModel>>();
  const { mutate: postQuickSearch } = usePostQuickSearch({
    onSuccess: (d) => {
      setMentionResults(d);
      d?.data?.forEach((item) => {
        fetchClient.setQueryData(searchCacheKeys.searchItem(item.guid), item);
      });
    },
  });

  const { mentionSuggestionElement, onChangeWithMentions, onKeyDownWithMentions } = useMentions({
    defaultFilter: customMentionFilters,
    editor,
    onSearch: (searchTerm, filters) =>
      postQuickSearch({
        all_buckets_search: true,
        index_filters: filters || defaultMentionFilters,
        page_size: 50,
        query: searchTerm,
      }),
    searchResults: mentionResults,
    searchTabs: customSearchTabs,
  });

  const descriptionOptions = useDescriptionOptions({
    aiDescription,
    ingestedDescription,
    suggestedDescription,
    suggestedDescriptionSource,
    userDescription,
  });

  const toggleMarkdown = () => {
    const currentRichText = JSON.stringify(editor.children);
    if (currentRichText === cache.toMarkdown?.richText) {
      setShowMarkdownEditor(true);
    } else {
      convertRichTextToMarkdown(currentRichText);
    }
  };

  const toggleRichText = () => {
    if (descriptionValue.currentMarkdown === cache.toRichText?.markdown) {
      setDescriptionValue((prevDescriptionValue) => ({
        ...prevDescriptionValue,
        convertedRichText: cache.toRichText?.richText || '',
      }));
      setShowMarkdownEditor(false);
    } else {
      operationTypeRef.current = 'convert';
      convertMarkdownToRichText(descriptionValue.currentMarkdown);
    }
  };

  const handleToggleEditor = (targetType: 'markdown' | 'richText') => {
    if (targetType === 'markdown') {
      toggleMarkdown();
    } else {
      toggleRichText();
    }
  };

  const resetEditorState = () => {
    setDescriptionValue({
      convertedRichText: '',
      currentMarkdown: '',
    });
    setCache({
      toMarkdown: undefined,
      toRichText: undefined,
    });

    setIsEditing(false);
    setShowMarkdownEditor(false);
    setIsFocused(false);
  };

  const handleSetSlateState = (state: Descendant[]) => {
    onChangeWithMentions(state, (newState: Descendant[]) => {
      if (shouldTruncate) {
        return;
      }
      setSlateEditorState(newState);
    });
  };

  const handleChange = (state: Descendant[]) => {
    handleSetSlateState(state);
    let stringifiedDescription = JSON.stringify(editor.children);
    if (isEmptyRichText(stringifiedDescription)) {
      stringifiedDescription = '';
    }
    if (stringifiedDescription !== cancelState) {
      const plainTextDescription = serializeSlateToPlainText({
        getMentionFromCacheById,
        nodes: editor.children,
      });
      onDescriptionChange?.(stringifiedDescription, plainTextDescription);
    }
  };

  const handleSaveMarkdown = () => {
    setIsFocused(false);
    operationTypeRef.current = 'save';
    convertMarkdownToRichText(descriptionValue.currentMarkdown);
  };

  const handleSaveRichText = () => {
    setFocusOnEdit(true);
    setIsFocused(false);
    let newDescription = JSON.stringify(editor.children);
    let source: DescriptionSource | undefined = 'user';
    const initialDescription = JSON.stringify(initialSlateState);

    if (newDescription === initialDescription) {
      resetEditorState();
      return;
    }

    if (isEmptyRichText(newDescription)) {
      newDescription = '';
      source = undefined;
    }

    const plainTextDescription = serializeSlateToPlainText({
      getMentionFromCacheById,
      nodes: editor.children,
    });

    onDescriptionSave?.(newDescription, plainTextDescription, source);
    setSlateEditorState(editor.children);
    setTruncatedSlateState(truncateSlate(editor.children, maxLengthValue));
    setCancelState(newDescription);
    setIsEditing(false);
  };

  const handleSave = () => {
    if (showMarkdownEditor) {
      handleSaveMarkdown();
    } else {
      handleSaveRichText();
    }
  };

  const useClickOutsideRef = useClickOutside<HTMLDivElement>({
    disabled: isBlockEditor || !isEditing,
    ignoreElements: ['legacy-modal', 'tooltip', 'modal'],
    onClick: () => {
      setIsFocused(false);
      if (!editMode) {
        handleSave();
      }
    },
  });

  const handleCancel = useCallback(() => {
    resetEditorState();
    setShowMore(false);
    const slateCancelState = convertStringToSlate(cancelState);
    resetEditorValue(editor, slateCancelState);
    setSlateEditorState(slateCancelState);
  }, [cancelState, editor, setShowMore]);

  const {
    MarkdownEditorComponent,
    ToggleMarkdownComponent,
    convertMarkdownToRichText,
    convertRichTextToMarkdown,
  } = useMarkdownDescriptionEditor({
    allowedElements,
    descriptionValue,
    editor,
    editorVariant: variant,
    onCancel: handleCancel,
    onDescriptionSave,
    onSaveMarkdown: handleSaveMarkdown,
    onToggleEditor: handleToggleEditor,
    operationTypeRef,
    setCache,
    setDescriptionValue,
    setIsEditing,
    setShowMarkdownEditor,
    setSlateEditorState,
    textValue,
  });

  const handleClick = () => {
    if (isEditing || !isEditable) {
      return;
    }
    setIsEditing(true);
    setIsFocused(true);
  };

  const handleDiscardChanges = useCallback(() => {
    setIsEditing(false);
    setShowMore(false);
    const slateCancelState = convertStringToSlate(cancelState);
    resetEditorValue(editor, slateCancelState);
    setSlateEditorState(slateCancelState);
  }, [editor, cancelState, setShowMore]);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (isInlineEditor) {
      const { key } = event;
      switch (key) {
        case 'Enter':
          // When editing is block style we use shift + return to add a new line.
          if (event.shiftKey || disableSaveOnEnter) {
            return;
          }
          event.preventDefault();
          handleSaveRichText();
          break;
        default:
      }
    }
  };

  const hasUnsavedChanges = useMemo(
    () => JSON.stringify(editor.children) !== JSON.stringify(initialSlateState),
    [editor.children, initialSlateState],
  );

  const handleShouldCancel = () => {
    if (blockNavigation && hasUnsavedChanges) {
      openModal(MODAL_IDS.leavePageConfirmationEditorNavigationBlocker);
    } else {
      handleCancel();
    }
  };

  const descriptionSelector = (
    <DescriptionSelector
      dataSourceType={dataSourceType}
      dataTypes={dataTypes}
      descriptions={descriptionOptions}
      descriptionSource={descriptionSource}
      guid={guid}
      isEditable={isEditable}
      onSelectDescription={(selectedDescription) => {
        onDescriptionSave?.(
          selectedDescription.richTextDescription || '',
          selectedDescription.description,
          selectedDescription.descriptionSource,
        );
      }}
      parentGuid={parentGuid}
      small={isInlineEditor}
      suggestedDescriptionSourceObject={suggestedDescriptionSourceObject}
    />
  );

  const saveCancelButtons = isEditing && !editMode && (
    <Box compDisplay="flex" flexWrap="nowrap" gap={1}>
      <Button compSize="sm" onClick={handleShouldCancel} variant="outlined">
        Cancel
      </Button>
      <Button compSize="sm" disabled={isLoading} onClick={() => handleSave()}>
        Save
      </Button>
    </Box>
  );

  useEffect(() => {
    setIsEditing(forceEditModeOnStart || editMode);
  }, [forceEditModeOnStart, editMode]);

  useEffect(() => {
    if (isInlineEditor) {
      if (isSuccess && toastTitle) {
        renderInfoToast(toastTitle);
      }
      setDescriptionValue({
        convertedRichText: '',
        currentMarkdown: '',
      });
      setCache({
        toMarkdown: undefined,
        toRichText: undefined,
      });
    }
  }, [isInlineEditor, isSuccess, toastTitle]);

  useEffect(() => {
    if (textValue !== cancelState) {
      const newSlateState = convertStringToSlate(textValue);
      setSlateEditorState(newSlateState);
      resetEditorValue(editor, newSlateState);
      setTruncatedSlateState(() => truncateSlate(newSlateState, maxLengthValue));
      setCancelState(textValue);
    }
  }, [cancelState, editor, maxLengthValue, setTruncatedSlateState, textValue]);

  const wrapperType = variant === 'block' ? 'border' : 'default';
  const Wrapper = WrapperVariants[wrapperType];
  const wrapperProps = {
    border: { pb: 2.5, pt: 1.8, px: 3.5, ...(customWrapperProps as CardProps) },
    default: { compWidth: '100%', p: 0 },
  };

  return (
    <ErrorBoundary fallback={InlineUnexpectedError}>
      <Wrapper {...wrapperProps[wrapperType]}>
        {isBlockEditor && !isEditing && (
          <Box
            alignItems="center"
            compDisplay="flex"
            compWidth="100%"
            justifyContent="space-between"
            mb={1}
          >
            <Box alignItems="center" compDisplay="flex">
              <Text as="h3" color="gray.700" fontSize={titleFontSize} fontWeight="medium" m={0}>
                {title}
              </Text>
              {showDescriptionSelector && (
                <Box alignItems="center" compDisplay="flex" pl={0.5}>
                  {descriptionSelector}
                </Box>
              )}
            </Box>
            {isEditable && (
              <Button
                color={theme.colors.v1.gray[700]}
                compSize="sm"
                onClick={handleClick}
                startIcon={<Icon color={theme.colors.v1.gray[700]} name="edit-outline" />}
                variant="outlined"
              >
                Edit
              </Button>
            )}
          </Box>
        )}
        {isBlockEditor && isError && <Message content="Error saving your edit" error />}
        <Box alignItems="center" compDisplay="flex" compWidth="100%">
          {showDescriptionSelector &&
            isInlineEditor &&
            editIconVariant === 'hover' &&
            !isEditing &&
            descriptionSelector}
          <StyledRichTextDescriptionEditor
            ref={useClickOutsideRef}
            editIconVariant={editIconVariant}
            editorIsFocused={isFocused}
            fontSize={
              fontSize ||
              (isInlineEditor ? theme.typography.fontSizes.body1 : theme.typography.fontSizes.body2)
            }
            isEditing={isEditing}
            onClick={isInlineEditor ? handleClick : undefined}
            variant={variant}
          >
            {!showMarkdownEditor && (
              <Box noDefault>
                <Box alignItems="center" compDisplay="flex" gap={0.5} noDefault>
                  {showEditIcon && <Icon color="gray.500" name="edit-outline" size="16px" />}
                  <Box compWidth="100%" noDefault>
                    <Box compWidth="100%" maxHeight={maxHeight} noDefault overflow="auto">
                      <StyledInlineRichTextEditorContent>
                        <StyledRichTextEditor
                          allowedElements={allowedElements}
                          controlsRightElement={shouldShowToolbar && saveCancelButtons}
                          editable={isEditable && isEditing}
                          editIconVariant={editIconVariant}
                          editor={editor}
                          elementMap={{ mention: FetchedMention }}
                          initialState={slateEditorState}
                          isEditing={isEditing}
                          isSuggested={isSuggestion && !isEditing}
                          mentionSuggestionElement={mentionSuggestionElement}
                          mentionTriggerHandler={onKeyDownWithMentions}
                          onCancel={handleShouldCancel}
                          onChange={handleChange}
                          onFocus={() => setIsFocused(true)}
                          onKeyDown={handleKeyDown}
                          placeholder={isEditing ? placeholder : ''}
                          shouldFocusOnEdit={focusOnEdit}
                          showToolbar={shouldShowToolbar}
                          textToHighlight={searchTermsToHighlight}
                          variant={variant}
                        />
                        {isInlineEditor && !isEditing && !textValue && (
                          <Text color="gray.400" fontSize={theme.typography.fontSizes.body2}>
                            {placeholder}
                          </Text>
                        )}
                      </StyledInlineRichTextEditorContent>
                    </Box>
                    {markdownEnabled && isEditing && (
                      <Box borderTop={`1px solid ${theme.colors.gray[200]}`} px={1} py={1}>
                        {ToggleMarkdownComponent}
                      </Box>
                    )}
                  </Box>
                  {isInlineEditor && showEdit && editIconVariant === 'hover' && (
                    <StyledEditIcon name="edit-outline" variant={editIconVariant} />
                  )}
                  {showDescriptionSelector &&
                    isInlineEditor &&
                    editIconVariant === 'always' &&
                    !isEditing &&
                    descriptionSelector}
                </Box>
                {isShowButtonVisible && (
                  <ShowMoreDescriptionButton ml={showEditIcon ? 2.5 : 0} onClick={handleShowMore}>
                    {shouldTruncate ? 'Show more' : 'Show less'}
                  </ShowMoreDescriptionButton>
                )}
              </Box>
            )}
            {showMarkdownEditor && isEditing && MarkdownEditorComponent}
          </StyledRichTextDescriptionEditor>
        </Box>
      </Wrapper>
      <EditorNavigationBlocker
        onConfirm={handleSave}
        onDiscard={handleDiscardChanges}
        shouldBlockNavigation={blockNavigation && isEditing && hasUnsavedChanges}
      />
    </ErrorBoundary>
  );
};

export default RichTextDescriptionEditor;
