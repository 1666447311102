import React from 'react';
import { LOADING_TEXT } from '@constants';

import HR from '@components/UI/HR';
import Select, { SelectValue } from '@components/UI/Select';
import { NoResultsMessage } from '@components/UI/Select/OptionsList/OptionsList.styles';
import type { SelectProps } from '@components/UI/Select/Select';
import type { Option } from '@components/UI/Select/types';

export interface SearchProps
  extends Pick<
    SelectProps,
    | 'error'
    | 'label'
    | 'helperText'
    | 'options'
    | 'placeholder'
    | 'isDisabled'
    | 'isLoading'
    | 'searchValue'
    | 'renderCustomOption'
    | 'optionsListHeaderElement'
    | 'disableFiltering'
  > {
  onResultSelect?: (option: Option) => void;
  onSearchChange?: SelectProps['onSearchValueChange'];
}

const Search: React.FC<SearchProps> = ({
  disableFiltering = true,
  error,
  helperText,
  isDisabled,
  isLoading,
  label,
  onResultSelect,
  onSearchChange,
  options,
  optionsListHeaderElement,
  placeholder = 'Search...',
  renderCustomOption,
  searchValue,
  ...other
}) => {
  const handleChange = (newOption: SelectValue) => {
    if (newOption && newOption?.length > 0) {
      const [selectedItem] = newOption;
      onResultSelect?.(selectedItem);
    }
  };

  return (
    <Select
      disableFiltering={disableFiltering}
      error={error}
      fixedClearButton
      helperText={helperText}
      hideCaret
      isDisabled={isDisabled}
      isDropdown={false}
      isLoading={isLoading}
      isMulti
      label={label}
      onChange={handleChange}
      onSearchValueChange={onSearchChange}
      optionListMaxHeight="250px"
      options={options}
      optionsFitAnchorWidth
      optionsListHeaderElement={optionsListHeaderElement}
      placeholder={placeholder}
      renderCustomOption={renderCustomOption}
      renderEmptyMessage={() => (
        <>
          <HR mb={0} mt={0} />
          <NoResultsMessage>{isLoading ? LOADING_TEXT : 'No matches'}</NoResultsMessage>
        </>
      )}
      searchValue={searchValue}
      shouldBlockOnLoading={false}
      showClearButton
      showOptionsOnSearch
      value={[]}
      {...other}
    />
  );
};

export default Search;
