import React from 'react';
import getContainerPageConfig from '@configs/container/getContainerConfig';
import { useHistory, useLocation, useParams } from '@routing/router';

import { useFetchFolder } from '@api/bifolder';
import { useFetchDashboards } from '@api/dashboards';
import { DashboardModel } from '@api/dashboards/DashboardModel';
import invalidateCache from '@api/invalidateCache';
import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';
import BulkButtons from '@components/BulkButtons/BulkButtons';
import { FiltersSidebar } from '@components/FiltersSidebar';
import { GridContainer } from '@components/Grid';
import PageHeaderBar from '@components/PageHeaderBar';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { StyledTablesPage } from '@pages/TablesPage/TablesPage.styles';
import { setParams, useUpdateFilters } from '@utils/filters';
import MetadataDecorator from '@utils/MetadataDecorator';
import pluralize from '@utils/pluralize';
import useSearchParamsDataTypes from '@utils/useSearchParamsDataTypes';

import dashboardQuery from './dashboardQuery';
import DashboardsTable, {
  DASHBOARD_TABLE_SEARCH_CONFIG,
  DASHBOARD_TABLE_SORT_CONFIG,
} from './DashboardsTable';

const DashboardsPage: React.FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { dsGuid, guid } = useParams<{ dsGuid?: string; guid: string }>();
  const { dataSources } = useUserContext();
  const { permissions } = useObjectPermissionsContext();
  const searchParamsTypes = useSearchParamsDataTypes();

  const { data: folderData } = useFetchFolder(guid, {
    enabled: Boolean(guid),
    params: { query: '{breadcrumbs, data_source{guid}}' },
  });

  const config = getContainerPageConfig({
    dataTypes: searchParamsTypes,
    pathname,
  });

  const dataSourceGuid = dsGuid ?? folderData?.dataSource.guid ?? '';
  const dataTypes = searchParamsTypes ?? config?.dataTypes;

  const filterService = useUpdateFilters(
    { order: config?.order, page: 1, page_size: 100 },
    DASHBOARD_TABLE_SEARCH_CONFIG,
    DASHBOARD_TABLE_SORT_CONFIG,
    config?.order,
  );
  const { data, isLoading } = useFetchDashboards({
    enabled: !!dataSourceGuid,
    params: {
      ...setParams(filterService.filter),
      data_type: searchParamsTypes?.dataType,
      datasources: dataSourceGuid,
      query: dashboardQuery,
    },
  });

  const { reset, selected, tagsCounts, toggleAll, toggleItem } =
    useBulkEditSelected<DashboardModel>({
      key: `${dsGuid} ${guid} ${searchParamsTypes?.dataType}`,
    });

  const isDataSourceEditable = Boolean(dataSources?.[dataSourceGuid]?.settings?.isEditable);
  const selectedEditableItems = selected.items.filter((item) => permissions[item.guid]?.isEditable);

  const reload = () => {
    invalidateCache((keys) => [keys.dashboards.all]);
  };

  const itemCount =
    !isLoading &&
    config?.countItems
      .map(({ key, text }) => {
        const count = (data as any)?.[key];
        return `${pluralize(count, text)}`;
      })
      .join(' & ');

  return (
    <Box compDisplay="flex">
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <MetadataDecorator title={config?.title} />
        <StyledTablesPage>
          <PageHeaderBar
            icon={dataTypes && <Icon name={dataTypes.icons.dataType} />}
            sticky
            supIcon={dataTypes && <Icon name={dataTypes.icons.dataSource} />}
            supTitle={
              folderData?.breadcrumbList && <Breadcrumbs items={folderData?.breadcrumbList} />
            }
            title={config?.title}
            titleSuffix={itemCount}
          />
          <BulkButtons
            canEditTags
            canRemove={!filterService.filter.is_hidden}
            canUnHide={filterService.filter.is_hidden}
            isDataSourceEditable={isDataSourceEditable}
            onEdit={() => {
              reload();
              reset();
            }}
            selectedEditableItems={selectedEditableItems}
            selectedItems={selected.items}
            showText
            tagsCounts={tagsCounts}
          />
          <Box mb={0.5}>
            <DashboardsTable
              data={data}
              filterService={filterService}
              isDataSourceEditable={isDataSourceEditable}
              isLoading={isLoading}
              selectedRowIds={selected.ids}
              toggleAll={(checked) => {
                toggleAll(data?.results!, checked);
              }}
              toggleItem={toggleItem}
            />
          </Box>
        </StyledTablesPage>
      </GridContainer>
      <FiltersSidebar
        {...config?.filter}
        dataSourceType={dataTypes?.dataSourceType}
        filterService={filterService}
        onSelectDataType={(value) => {
          history.replace({ pathname, search: value });
        }}
      />
    </Box>
  );
};

export default DashboardsPage;
