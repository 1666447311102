import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from '@sentry/react';

import Alert from '@components/Alert';
import AddDiscoveryDB from '@components/DataSourceSetup/components/AddDiscoveryDB';
import AuthorizeAWS from '@components/DataSourceSetup/components/AuthorizeAWS';
import { Column } from '@components/Grid';
import AddDSHeader from '@components/InformationScreen/AddDSHeader';
import { SidebarSteps } from '@components/Modal/ModalWithSidebar';
import { ModalFooter, ModalHeader } from '@components/UI/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackPageName } from '@context/Segment/Segment.types';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';
import useStep from '@utils/step';

import BIFolderEditConfigStep from './components/BIFolderEditConfigStep';
import ConnectQueryLogForm from './components/ConnectQueryLogForm';
import DatabaseIngestionConfigStep from './components/DatabaseIngestionConfigStep';
import DataSourceAddActivityDataStep from './components/DataSourceAddActivityDataStep';
import DataSourceAddStep, { BoxWrapper } from './components/DataSourceAddStep';
import DataSourceConfigureStep from './components/DataSourceConfigureStep';
import IngestDataStep from './components/IngestDataStep';
import InitIngestionConfigStep from './components/InitIngestionConfigStep';
import LookMLProjectSetup from './components/LookMLProjectSetup';
import SchemaIngestionConfigStep from './components/SchemaIngestionConfigStep';
import SetupCompleteStep from './components/SetupCompleteStep';
import { StyledDataSourceSetupContent } from './DataSourceSetup.styles';
import { getStepsForDS, getStepStartIndex } from './steps';

export interface DataSourceSetupContentProps {
  /**
   * Providing 'dataSource' param will induce edit mode.
   */
  dataSource?: DataSourceModel;
  /**
   * Differences based on if this modal is from the onboarding page or not
   */
  isOnboarding?: boolean;
  onClose?: () => void;
  onSuccess: () => void;
  /**
   * Providing a type will update the initial type
   */
  type?: DataSourceTypesType;
}

const DataSourceSetupContent: React.FC<DataSourceSetupContentProps> = ({
  dataSource: defaultDataSource,
  isOnboarding = false,
  onClose,
  onSuccess,
  type,
}) => {
  const segment = useSegmentContext();
  const [dataType, setDataType] = React.useState<DataSourceTypesType>(type || 'snowflake');
  const [dataSource, setDataSource] = useState<DataSourceModel | undefined>(defaultDataSource);

  // lastFullyIngestedOn is only set when full ingestion is complete
  const isIngestionComplete = dataSource !== undefined && Boolean(dataSource?.lastFullyIngestedOn);

  const steps = useMemo(
    () => getStepsForDS(dataType, isOnboarding, isIngestionComplete),
    [dataType, isOnboarding, isIngestionComplete],
  );
  const stepStartIndex = getStepStartIndex(dataSource, isIngestionComplete);

  const { currStep, nextStep, prevStep } = useStep({ startIndex: stepStartIndex, steps });

  useEffect(() => {
    segment?.page(SegmentTrackPageName.ImportData, { dataType: type });
  }, []);

  const handleDataSourceAdded = useCallback(
    (ds: DataSourceModel) => {
      setDataSource(ds);
      nextStep();
    },
    [nextStep],
  );

  const handleConfigUpdated = useCallback(() => {
    nextStep();
  }, [nextStep]);

  return (
    <StyledDataSourceSetupContent>
      <Column
        backgroundColor="rgb(242, 245, 249)"
        borderRadius="8px 0 0 8px"
        compDisplay="flex"
        flexDirection="column"
        hideDown={['md']}
        md={4}
        noDefault
      >
        <ModalHeader
          justifyContent="center"
          title={defaultDataSource ? 'Update Metadata' : 'Add Data Source'}
        />
        <SidebarSteps activeStep={currStep} steps={steps} />
        <ModalFooter mt="auto" />
      </Column>
      <Column compDisplay="flex" flexDirection="column" md={8} xs={12}>
        <ModalHeader onClose={onClose} title={currStep.key !== 'add-ds' && <AddDSHeader />} />
        <ErrorBoundary
          fallback={
            <BoxWrapper>
              <Alert type="error">
                An unexpected error occurred. Please retry your action, or contact
                support@getselectstar.com if the issue persists.
              </Alert>
            </BoxWrapper>
          }
        >
          {currStep.key === 'add-ds' && (
            <DataSourceAddStep
              dataSource={dataSource}
              dataType={dataType}
              onDataSourceAdded={handleDataSourceAdded}
              setDataType={setDataType}
            />
          )}
          {currStep.key === 'authorize-aws-rds' && dataSource && (
            <AuthorizeAWS dataSource={dataSource} onDataSourceAdded={handleDataSourceAdded} />
          )}
          {currStep.key === 'select-lookml-projects' && (
            <LookMLProjectSetup
              guid={dataSource?.guid ?? ''}
              nextStep={nextStep}
              prevStep={prevStep}
            />
          )}
          {currStep.key === 'connect-query-log' && (
            <ConnectQueryLogForm dataSource={dataSource} onSuccess={nextStep} />
          )}
          {currStep.key === 'add-usage-ds' && dataSource && (
            <DataSourceAddActivityDataStep
              dataSource={dataSource}
              dataType={dataType}
              isOnboarding={isOnboarding}
              onDataSourceAdded={handleDataSourceAdded}
              prevStep={prevStep}
            />
          )}
          {currStep.key === 'configure' && dataSource && (
            <DataSourceConfigureStep
              dataSource={dataSource}
              dataType={dataType}
              isOnboarding={isOnboarding}
              onDataSourceAdded={handleDataSourceAdded}
              prevStep={prevStep}
            />
          )}
          {currStep.key === 'add-discovery-db' && dataSource && (
            <AddDiscoveryDB
              dataSource={dataSource}
              onDataSourceAdded={handleDataSourceAdded}
              prevStep={prevStep}
            />
          )}
          {currStep.key === 'select-dbs' && dataSource && (
            <DatabaseIngestionConfigStep
              dataSource={dataSource}
              onConfigUpdated={handleConfigUpdated}
              prevStep={prevStep}
            />
          )}
          {currStep.key === 'select-bi-folders' &&
            !isOnboarding &&
            isIngestionComplete &&
            dataSource && (
              <BIFolderEditConfigStep
                dataSource={dataSource}
                onBIFoldersUpdated={handleConfigUpdated}
                prevStep={prevStep}
              />
            )}
          {currStep.key === 'select-schemas' && dataSource && (
            <SchemaIngestionConfigStep
              dataSource={dataSource}
              onConfigUpdated={handleConfigUpdated}
              prevStep={prevStep}
            />
          )}
          {currStep.key === 'init-config' && dataSource && (
            <InitIngestionConfigStep
              dataSource={dataSource}
              onConfigUpdated={handleConfigUpdated}
              prevStep={prevStep}
            />
          )}
          {currStep.key === 'load' && dataSource && (
            <IngestDataStep
              dataSource={dataSource}
              onFinish={isOnboarding ? onSuccess : nextStep}
              waitForFinish={!isOnboarding}
            />
          )}
          {currStep.key === 'finish' && dataSource && (
            <SetupCompleteStep dataSource={dataSource} onSuccess={onSuccess} />
          )}
        </ErrorBoundary>
      </Column>
    </StyledDataSourceSetupContent>
  );
};

export default DataSourceSetupContent;
