import React from 'react';
import { RouterLink } from '@routing/router';
import { Cell } from 'react-table';

import { PullRequestModel } from '@api/syncBack/PullRequestModel';
import Box from '@components/Box';
import DateTime from '@components/DateTime/DateTime';
import Link from '@components/Link';
import type { ColumnConfig } from '@components/Table/Table/types';
import Text from '@components/Text/Text';
import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';
import { Filter } from '@utils';

import Table from '../Table';
import TableStyled from '../TableStyled';

const INITIAL_SORT_STATE = [
  {
    desc: true,
    id: 'createdOn',
  },
];

interface DbtDocsSyncTableProps {
  data?: PullRequestModel[];
  filterService: Filter.FilterServiceInterface;
  loading?: boolean;
  noRepository?: boolean;
  totalPages?: number;
}

const DbtDocsSyncTable: React.FC<DbtDocsSyncTableProps> = ({
  data,
  filterService,
  loading,
  noRepository,
  totalPages,
}) => {
  const { changePage, filter, sort } = filterService;
  const columns: ColumnConfig<PullRequestModel>[] = React.useMemo(
    () => [
      {
        Cell: (props: Cell<PullRequestModel>) => {
          const {
            row: { original: pullRequest },
          } = props;
          return (
            <Box compDisplay="flex" flexDirection="column" lineHeight="normal" my={1.5}>
              <Box mb={3}>
                <Link
                  color="inherit"
                  fontSize="body2"
                  href={pullRequest.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  underline
                  underlineOnHover
                >
                  {pullRequest.remoteTitle}
                </Link>
              </Box>
              <Text color="inherit" fontSize="body2" lineHeight={2} whiteSpace="pre-wrap">
                {pullRequest.localBody}
              </Text>
            </Box>
          );
        },
        Header: 'Pull Request',
        accessor: (t) => t?.remoteTitle,
        disableFilters: true,
        disableHiding: true,
        disableSortBy: true,
        id: 'name',
        width: '150%',
      },
      {
        Cell: (props: Cell<PullRequestModel>) => {
          const {
            row: { original: pullRequest },
          } = props;
          return (
            <Box alignItems="center" compDisplay="flex">
              <Icon
                mr={1}
                name={pullRequest.repository.provider as IconProps['name']}
                size="16px"
              />
              <Link
                color="inherit"
                fontSize="body2"
                href={pullRequest.repository.url}
                rel="noopener noreferrer"
                target="_blank"
                underline
                underlineOnHover
              >
                {pullRequest.repository.name}
              </Link>
            </Box>
          );
        },
        Header: 'Repo',
        accessor: (t) => t?.repository,
        disableSortBy: true,
        id: 'repo',
        width: '110%',
      },
      {
        Cell: (props: Cell<PullRequestModel>) => {
          const {
            row: { original: pullRequest },
          } = props;
          return <DateTime datetime={pullRequest.createdOn} format="fullDateFormat" />;
        },
        Header: 'Created at',
        accessor: (t) => t?.createdOn,
        id: 'createdOn',
        width: '140%',
      },
    ],
    [],
  );

  const noRepoConnectedMessage = (
    <Text color="inherit" fontSize="inherit">
      No Repo connected. Go to&nbsp;
      <Link
        as={RouterLink}
        color="inherit"
        compDisplay="inline"
        fontSize="inherit"
        to="/admin/apps"
        underline
        underlineOnHover
      >
        Apps page
      </Link>
      &nbsp;to connect a Git Repo.
    </Text>
  );

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        data={data || []}
        disableColumnFiltering
        disablePagination
        disableRowSelect
        emptyMessage={noRepository ? noRepoConnectedMessage : undefined}
        initialState={{
          pageIndex: filter.page ? filter.page - 1 : 0,
          sortBy: INITIAL_SORT_STATE,
        }}
        loading={loading}
        manualPagination
        manualSortBy
        setSortBy={sort}
        singleLine
        sortable
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default DbtDocsSyncTable;
