import 'semantic-ui-css/semantic.min.css';
import 'reactflow/dist/style.css';

import React from 'react';
import { ErrorBoundary } from '@sentry/react';

import AppHeader from '@components/AppHeader';
import Box from '@components/Box';
import UnexpectedError from '@components/Error/UnexpectedError';
import ModalContainer from '@components/Modal/ModalContainer';
import ToastContainer from '@components/ToastContainer';
import useNewLayout from '@hooks/useNewLayout';

import { LayoutContent, StyledLayout, StyledLayoutWrapper } from './Layout.styles';

interface LayoutProps {
  headerComponent?: React.ReactNode;
  leftSidebar?: React.ReactNode;
  rightSidebar?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  headerComponent = <AppHeader />,
  leftSidebar,
  rightSidebar,
}) => {
  const { shouldUseNewLayout } = useNewLayout();
  return (
    <>
      <StyledLayout>
        {shouldUseNewLayout && leftSidebar}
        <StyledLayoutWrapper>
          {headerComponent}
          <LayoutContent>
            <ErrorBoundary fallback={UnexpectedError}>
              {!shouldUseNewLayout && leftSidebar}
              <Box
                flexGrow={1}
                id="main-content"
                minHeight="100%"
                minWidth={0}
                noDefault
                position="relative"
                zIndex={0}
              >
                {children}
              </Box>
              {rightSidebar}
            </ErrorBoundary>
          </LayoutContent>
        </StyledLayoutWrapper>
      </StyledLayout>
      <ToastContainer fixed />
      <ModalContainer />
    </>
  );
};

export default Layout;
