import findAllDeep from '@utils/findAllDeep';

import type { IndexedOption, Option } from '../types';

export const getFiltered = ({
  inputValue,
  isTree,
  options,
}: {
  inputValue: string;
  isTree?: boolean;
  options: IndexedOption[];
}) => {
  if (isTree) {
    if (!inputValue) {
      return options;
    }

    const results: IndexedOption[] = [];
    options.forEach((optionLevel0) => {
      const filteredOptions = findAllDeep({
        searchFieldName: 'text',
        searchText: inputValue,
        tree: optionLevel0.children,
      });

      if (filteredOptions.length > 0) {
        const item = {
          ...optionLevel0,
          children: filteredOptions,
        };
        results.push(item);
      }
    });

    return results;
  }

  return inputValue
    ? options.filter((el) => el.text.toLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1)
    : options;
};

export const isMatch = (a?: Option | null, b?: Option | null) => a?.value === b?.value;

export const flatExpandedChildren = (
  tree: any,
  expandedKeys: string[] = [],
  result: any[] = [],
) => {
  if (tree == null) {
    return result;
  }
  if (expandedKeys.includes(tree.value)) {
    tree.children.forEach((node: any) => {
      result.push(node);
      flatExpandedChildren(node, expandedKeys, result);
    });
  }

  return result;
};

export const removeDuplicatedOptions = (options: Option[]) => {
  return options.filter((option, index, self) => {
    return self.findIndex((el) => el.value === option.value) === index;
  });
};

export const isAllFilteredItemsSelected = (selectedItems: Option[], filteredItems: Option[]) => {
  const haveSameLength = selectedItems.length === filteredItems.length;
  if (!haveSameLength) return false;

  const filteredItemsValues = filteredItems.map((item) => item.value);

  return filteredItemsValues.every((value) => selectedItems.some((item) => item.value === value));
};

export const stringifyOptions = (options: IndexedOption[]) => {
  return JSON.stringify(options.map(({ id, value }) => ({ id, value })));
};

export const calculateOptionListMaxHeight = (
  scrollBoxElement: HTMLDivElement | null,
  maxOptionsVisible?: number,
) => {
  const scrollBoxChildren = scrollBoxElement?.children;
  if (
    scrollBoxChildren?.length &&
    scrollBoxChildren?.length > 0 &&
    Number.isInteger(maxOptionsVisible)
  ) {
    const elementsHeight = Array.from(scrollBoxChildren).map((el) => el.clientHeight);
    const totalVisibleHeight = elementsHeight
      .slice(0, maxOptionsVisible)
      .reduce((acc, cur) => acc + cur, 0);

    return `${totalVisibleHeight}px`;
  }

  return undefined;
};
