import React from 'react';
import chunk from 'lodash/chunk';

import { DashboardModel } from '@api/dashboards/DashboardModel';
import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import { LookMLViewModel } from '@api/lookML/LookMLViewModel';
import { TableauDataSourceModel } from '@api/tableau/TableauDataSourceModel';
import { TableModel } from '@api/tables/TableModel';
import { TagModel } from '@api/tags/TagModel';
import Box from '@components/Box';
import Text from '@components/Text';
import { StyledTextProps } from '@components/Text/Text';
import Card, { CardProps } from '@components/UI/Card';

import DETAIL_SECTION_FIELD_CONFIG_BY_OBJECT, { DETAIL_SECTION_FIELD_CONFIG } from './config';
import {
  StyledDetailsList,
  StyledDetailsListColumn,
  StyledDetailsListItem,
  StyledDetailsListItemLabel,
} from './DetailsSection.styles';
import type { ConfigObject } from './DetailsSection.types';

interface DetailsSectionProps {
  cardProps?: CardProps;
  data?:
    | TableModel
    | DashboardModel
    | LookerExploreModel
    | TableauDataSourceModel
    | TagModel
    | LookMLViewModel;
  isEditable: boolean;
  reloadData?: () => void;
  titleFontSize?: StyledTextProps['fontSize'];
}

const DetailsSection: React.FC<DetailsSectionProps> = ({
  cardProps,
  data,
  isEditable,
  reloadData,
  titleFontSize = 'h4',
}) => {
  if (!data) return null;

  const fields =
    data.dataTypes?.findConfig<ConfigObject[]>(DETAIL_SECTION_FIELD_CONFIG) ??
    DETAIL_SECTION_FIELD_CONFIG_BY_OBJECT[data.objectType] ??
    [];
  const visibleFields =
    fields?.filter((field) => {
      return !field.hidden(data);
    }) || [];
  const columns = chunk(visibleFields, 4);

  return (
    <Card
      containerName="details-card"
      containerType="inline-size"
      pb={2}
      pt={2.5}
      px={3.5}
      {...cardProps}
    >
      <Box compDisplay="flex" mb={1}>
        <Text as="h3" color="gray.700" fontSize={titleFontSize} fontWeight="medium" m={0}>
          Details
        </Text>
      </Box>
      <StyledDetailsList length={visibleFields.length}>
        {columns.map((fieldChunk) => {
          return (
            <StyledDetailsListColumn key={`column-${fieldChunk[0].label}`}>
              {fieldChunk.map(({ customRenderSection, info, label, renderValue }) => {
                const renderProps = {
                  hasEditPermissions: isEditable,
                  reloadData,
                };
                if (customRenderSection) {
                  return customRenderSection(data, renderProps);
                }
                return (
                  <StyledDetailsListItem key={label} aria-label={label}>
                    {label && (
                      <StyledDetailsListItemLabel>
                        {label} {info}
                      </StyledDetailsListItemLabel>
                    )}
                    {renderValue?.(data, renderProps)}
                  </StyledDetailsListItem>
                );
              })}
            </StyledDetailsListColumn>
          );
        })}
      </StyledDetailsList>
    </Card>
  );
};

export default DetailsSection;
