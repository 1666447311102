import React from 'react';
import { RouterLink, useLocation, useParams } from '@routing/router';

import invalidateCache from '@api/invalidateCache';
import { searchCacheKeys } from '@api/search';
import { usePatchTag } from '@api/tags';
import Box from '@components/Box';
import CreateTagButton from '@components/CreateTagButton';
import getUrl from '@components/Tree/getUrl';
import TreeNode from '@components/Tree/TreeNode.v1';
import Icon from '@components/UI/Icon';
import { useTagsHierarchyContext } from '@context/TagsHierarchy';
import { useUserContext } from '@context/User';
import useNewLayout from '@hooks/useNewLayout';
import fetchClient from '@lib/fetchClient';
import { HierarchyData } from '@models/HierarchyModel';
import theme from '@styles/theme';

import HierarchyLoadingSkeleton from '../HierarchyLoadingSkeleton';
import RoutedHierarchyTree from '../RoutedHierarchyTree';

const UNDRAGGABLE_GUIDS_LIST = [
  'all-tags',
  'category_tag_container',
  'status_tag_container',
  'dbt_tag_container',
];
const allMenuItem: HierarchyData = {
  breadcrumbLabelList: [],
  children: [],
  guid: 'all-tags',
  iconEl: <Icon color={theme.colors.gray[700]} name="tag-outline" size="16px" />,
  name: 'All Tags',
  objectType: 'menu-item',
  url: '/tags',
};

const TagsHierarchy: React.FC = () => {
  const { shouldUseNewLayout } = useNewLayout();
  const { tagsHierarchy, tagsHierarchyIsLoading } = useTagsHierarchyContext();
  const { mutate } = usePatchTag(undefined, {
    onSuccess: (d) => {
      invalidateCache((keys) => [keys.tags.all, keys.tags.hierarchy]);
      fetchClient.setQueryData(searchCacheKeys.searchItem(d.guid), d);
    },
  });
  const location = useLocation();
  const { isOrgAdmin } = useUserContext();
  const { guid } = useParams<{ guid: string }>();
  const rootLevelKeys = tagsHierarchy?.map((item) => item.guid) ?? [];

  if (tagsHierarchyIsLoading) {
    return <HierarchyLoadingSkeleton />;
  }

  if (!tagsHierarchy || tagsHierarchy?.length === 0) {
    return <Box p={2}>No data.</Box>;
  }

  return (
    <Box compDisplay="flex" compHeight="100%" flexDirection="column">
      {isOrgAdmin && (
        <Box mb={1} px={2}>
          <CreateTagButton />
        </Box>
      )}
      <Box compDisplay="flex" flexDirection="column" overflowX="hidden" overflowY="auto">
        {[allMenuItem].concat(tagsHierarchy).map((d) => (
          <RoutedHierarchyTree
            key={d.guid}
            defaultExpandedKeys={[guid, ...rootLevelKeys]}
            defaultSelectedKeys={location.pathname === `/tags` ? [`all-tags`] : [guid]}
            draggable={(node) => {
              if (UNDRAGGABLE_GUIDS_LIST.includes(node.key as string)) {
                return false;
              }

              if (d.type === 'dbt') {
                return false;
              }

              return isOrgAdmin;
            }}
            keyAlias="guid"
            onDrop={({ dragNode, ordinal, parent }) => {
              mutate({
                httpClientUrl: `/tags/${dragNode.key}/`,
                ordinal,
                parent: String(parent)?.includes('container') ? null : parent,
              });
            }}
            treeData={[d]}
            useNewLayout={shouldUseNewLayout}
          >
            {({ children, item }) => {
              const { level = 1 } = item;
              const newLayoutPaddingLeft = level === 1 ? theme.space(1) : theme.space(level * 2);
              const paddingLeft = shouldUseNewLayout
                ? newLayoutPaddingLeft
                : theme.space(level * 2);

              return (
                <TreeNode
                  key={item.guid}
                  icon={
                    (item?.icon || item.iconEl) && (
                      <RouterLink to={getUrl(item) ?? '#'}>
                        {item.iconEl ?? <Icon color={item.color} name={item.icon!} size="16px" />}
                      </RouterLink>
                    )
                  }
                  style={{
                    paddingLeft,
                    paddingRight: theme.space(1),
                  }}
                  title={<RouterLink to={getUrl(item) ?? '#'}>{item.name}</RouterLink>}
                >
                  {children}
                </TreeNode>
              );
            }}
          </RoutedHierarchyTree>
        ))}
      </Box>
    </Box>
  );
};

export default TagsHierarchy;
