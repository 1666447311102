import { useEffect, useMemo, useRef } from 'react';

import { useFetchLineageNew } from '@api/lineage';
import { AVAILABLE_USAGE_TYPES, UsageTypeType } from '@api/lineage/types';
import useCustomAlgorithm from '@components/LineageExplore/useCreateNodesEdges/algorithm';
import createStacks from '@components/LineageExplore/useCreateNodesEdges/utils/createStacks';
import useGetConfigQueryParams from '@components/LineageExplore/useGetConfigQueryParams';
import useUserTracking from '@components/LineageExplore/useUserTracking';
import { renderErrorToast } from '@components/Toast';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import { InputNodesById } from '../LineageExplore.types';
import { LineageLevelType } from '../useLineageExplore/LineageExplore.context.types';

import { AlgorithmResult, CreateStacksResult } from './algorithm/types';

interface UseCreateNodesEdgesProps {
  columnGuid?: string;
  enabled?: boolean;
  tableGuid: string;
  usageTypes: UsageTypeType[];
  useRelevantLineage: boolean;
}

interface UseCreateNodesEdgesResult extends AlgorithmResult {
  columnInputNodesById?: InputNodesById;
  inputNodesById?: InputNodesById;
  isFetchColumnLevelLoading: boolean;
  isFetchLineageError: boolean;
  isFetchLineageLoading: boolean;
  stacksData?: CreateStacksResult;
}

export const calculateUsageTypeFilter = (usageTypes: UsageTypeType[]): string => {
  const validUsages = AVAILABLE_USAGE_TYPES.filter((usageType) => usageType !== 'none');
  return validUsages
    .filter((usageType) => !usageTypes.includes(usageType))
    .map((usageType) => `-${usageType}`)
    .join(',');
};

const useCreateNodesEdges = ({
  columnGuid,
  enabled = true,
  tableGuid,
  usageTypes,
  useRelevantLineage,
}: UseCreateNodesEdgesProps): UseCreateNodesEdgesResult => {
  const { track } = useUserTracking();
  const startParsingTime = useRef(0);
  const { enableBorderlineEdges, enableHorizontalGroups, minLevels } = useGetConfigQueryParams();

  const DEFAULT_REQUEST_PARAMS = {
    include_borderline_edges: enableBorderlineEdges,
    min_levels: minLevels,
    relevant_lineage: useRelevantLineage,
    usage_type: calculateUsageTypeFilter(usageTypes),
  };

  const { data, isError, isLoading } = useFetchLineageNew(tableGuid, {
    enabled,
    params: {
      ...DEFAULT_REQUEST_PARAMS,
      mode: columnGuid ? 'all' : 'table',
    },
  });

  const {
    data: columnLevelData,
    isError: isFetchColumnLevelError,
    isLoading: isFetchColumnLevelLoading,
  } = useFetchLineageNew(columnGuid ?? tableGuid, {
    enabled: enabled && !columnGuid,
    params: {
      ...DEFAULT_REQUEST_PARAMS,
      mode: 'all',
    },
  });

  const stacksData = useMemo(() => {
    if (!data?.data) {
      return undefined;
    }
    startParsingTime.current = Date.now();

    return createStacks({
      inputNodesById: data.data,
      options: {
        enableColumnEdges: false,
        enableHorizontalGroups,
        enableTableEdges: true,
        openAll: false,
      },
      startingTableId: tableGuid,
    });
  }, [data?.data, enableHorizontalGroups, tableGuid]);

  const { biggestConflictEndPerStack, edgesById, nodesById, nodesByStack } = useCustomAlgorithm({
    stacksData,
  });

  useEffect(() => {
    if (Object.keys(nodesById).length > 0) {
      const feParsingTime = Date.now() - startParsingTime.current;
      const beParsingTime = data?.requestMetadata?.duration ?? 0;
      track(SegmentTrackEventName.LineageExploreLoaded, {
        beParsingTime,
        feParsingTime,
        guid: columnGuid ?? tableGuid,
        level: columnGuid ? LineageLevelType.Column : LineageLevelType.Table,
        nodesCount: Object.keys(nodesById).length,
        totalParsingTime: feParsingTime + beParsingTime,
      });
    }
  }, [nodesById]);

  useEffect(() => {
    if (isFetchColumnLevelError) {
      renderErrorToast('Sorry, something went wrong while loading column level data');
    }
  }, [isFetchColumnLevelError]);

  return {
    biggestConflictEndPerStack,
    columnInputNodesById: columnLevelData?.data,
    edgesById,
    inputNodesById: data?.data,
    isFetchColumnLevelLoading,
    isFetchLineageError: isError,
    isFetchLineageLoading: isLoading,
    nodesById,
    nodesByStack,
    stacksData,
  };
};

export default useCreateNodesEdges;
