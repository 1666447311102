import styled from '@emotion/styled';

import { StyledInput } from '@components/Input/Input.v1.styles';

interface StyledSelectProps {
  isMultiString?: boolean;
}

export const StyledSelect = styled(StyledInput)<StyledSelectProps>`
  height: ${({ isMultiString, theme }) => (isMultiString ? theme.space(4.5) : 'unset')};
  padding-left: ${({ theme }) => theme.space(0.75)};
`;

StyledSelect.defaultProps = {
  alignItems: 'center',
  borderRadius: 'default',
  compDisplay: 'flex',
  compWidth: '100%',
  fontWeight: 'regular',
  justifyContent: 'space-between',
  maxWidth: 'unset',
  minHeight: '36px',
  noDefault: true,
  pr: 1.25,
  py: 0.5,
};
