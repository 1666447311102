import React, { useCallback, useState } from 'react';
import { RecoilState } from 'recoil';

import { FetchLineageParams, useFetchLineage, useFetchLineageCsv } from '@api/lineage';
import TableLineageModel from '@api/lineage/TableLineageModel';
import Box from '@components/Box';
import SidebarTree from '@components/ExploreSidebar/SidebarTree';
import { SidebarTreeProps } from '@components/ExploreSidebar/SidebarTree/SidebarTree';
import SidebarTreeV2 from '@components/ExploreSidebar/SidebarTree/SidebarTree.v2';
import { LoadLineageOptions } from '@components/ExploreSidebar/types';
import type { SearchOptions } from '@components/ExploreTree/atoms';
import TabError from '@components/TabContent/TabError';
import type { TabContentProps } from '@components/Tabs/types';
import Text from '@components/Text';
import { renderErrorToast } from '@components/Toast';
import { useUserContext } from '@context/User';
import flags from '@features';
import useMergeLineageData from '@hooks/useMergeLineageData';
import useMutation from '@hooks/useMutation';
import downloadCSV from '@utils/downloadCSV';

export interface UpstreamDownstreamTabProps extends TabContentProps {
  assetName: string;
  counts: SidebarTreeProps['counts'];
  customSearchOptions?: RecoilState<SearchOptions>;
  direction: 'left' | 'right';
  enableExportCsv?: boolean;
  mode?: 'table' | 'column';
  nodeKey: string;
  requestParamsExtraProps?: FetchLineageParams;
  showDescriptions?: boolean;
  showRelevantLineageToggle?: boolean;
  showUsage?: boolean;
  tableOrColumnId: string;
}

const MAX_LINEAGE_CSV_OBJECTS = 10000;

export const checkCsvLimit = (csvData: string) => {
  const csvLinesCount = csvData.split('\n').length - 1;
  if (csvLinesCount > MAX_LINEAGE_CSV_OBJECTS) {
    renderErrorToast(
      `CSV limit of ${MAX_LINEAGE_CSV_OBJECTS.toLocaleString()} rows has been applied. Please use the API to fetch additional data.`,
    );
  }
};

const getTableRelevantColumnsGuids = (lineageTable: TableLineageModel) => {
  const columnsLineageKeys = lineageTable?.columns ?? [];
  const relevantColumnsGuids = columnsLineageKeys
    .map((key) => {
      const [, columnGuid] = key.split('/');
      return columnGuid;
    })
    .join(',');

  return relevantColumnsGuids;
};

const UpstreamDownstreamTab: React.FC<UpstreamDownstreamTabProps> = ({
  assetName,
  counts,
  customSearchOptions,
  direction,
  enableExportCsv = false,
  mode = 'table',
  nodeKey,
  requestParamsExtraProps = {},
  showDescriptions = true,
  showRelevantLineageToggle,
  showUsage = false,
  tableOrColumnId,
}) => {
  const { organization } = useUserContext();
  const [guid, setGuid] = useState(tableOrColumnId);
  const [requestParams, setRequestParams] = useState<FetchLineageParams>({
    dbt_links: true,
    direction,
    enable_tags: false,
    group_by_data_source: true,
    include_borderline_edges: true,
    looker_db_lineage: true,
    looker_view_lineage: flags.looker_view_lineage,
    max_depth: 1,
    mode,
    mode_lineage: false,
    relevant_lineage: false,
    tableau_table_lineage: true,
    ...requestParamsExtraProps,
  });

  const { data, isError, isFetching, isLoading } = useFetchLineage(guid, {
    enabled: Boolean(tableOrColumnId),
    keepPreviousData: true,
    params: {
      ...requestParams,
    },
  });

  const { isLoading: isLoadingExportCsv, refetch: fetchExportLineage } = useFetchLineageCsv(
    tableOrColumnId,
    {
      enabled: false,
      onError: (error) => {
        const errorMsg = error?.data?.[0] ?? 'Sorry, something went wrong';
        renderErrorToast(errorMsg);
      },
      onSuccess: (csvData: string) => {
        const parsedDirection = direction === 'left' ? 'upstream' : 'downstream';
        checkCsvLimit(csvData);
        downloadCSV(csvData, `${assetName}_${parsedDirection}_lineage.csv`);
      },
      params: {
        dbt_links: requestParams.dbt_links,
        direction: direction === 'left' ? 'upstream' : 'downstream',
        relevant_lineage: requestParams.relevant_lineage,
      },
      refetchOnWindowFocus: false,
    },
  );

  const lineageDataMerged = useMergeLineageData(data);

  const loadLineage = useCallback(
    (id: string, dir?: string, options?: LoadLineageOptions) => {
      setGuid(id);

      const enableRelevantLineage = organization?.settings?.useRelevantLineage
        ? requestParams.relevant_lineage
        : undefined;
      const table = lineageDataMerged.tablesById[id];
      const relevantColumnsGuids = getTableRelevantColumnsGuids(table);

      setRequestParams((prev) => ({
        ...prev,
        direction: dir ?? 'all',
        include_borderline_edges: options?.maxDepth !== 'max' || enableRelevantLineage,
        max_depth: options?.maxDepth ?? 1,
        relevant_columns: enableRelevantLineage ? relevantColumnsGuids : undefined,
        relevant_lineage: enableRelevantLineage,
      }));
    },
    [requestParams.relevant_lineage, organization?.settings?.useRelevantLineage],
  );

  const { mutateAsync } = useMutation({
    method: 'GET',
  });

  const handleDataLoad = useCallback(
    (id: string) => {
      return mutateAsync({
        httpClientUrl: `/lineage/${id}/`,
        max_depth: 1,
        ...requestParams,
      });
    },
    [mutateAsync, requestParams],
  );

  const handleExportCsvClick = () => {
    fetchExportLineage();
  };

  const handleUseRelevantLineageChange = (newValue: boolean) => {
    setGuid(tableOrColumnId);
    const table = lineageDataMerged.tablesById[guid];
    const relevantColumnsGuids = getTableRelevantColumnsGuids(table);
    setRequestParams((prev) => ({
      ...prev,
      relevant_columns: newValue ? relevantColumnsGuids : undefined,
      relevant_lineage: newValue,
    }));
  };

  if (isError) return <TabError />;

  if (!nodeKey || (lineageDataMerged?.[`${mode}s`].length === 0 && !isLoading)) {
    return (
      <Text color="inherit" mt={2}>
        No lineage detected.
      </Text>
    );
  }

  return (
    <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={2}>
      {flags.lineage_list_tree_v2 ? (
        <SidebarTreeV2
          columns={lineageDataMerged.columns}
          counts={counts}
          customSearchOptions={customSearchOptions}
          direction={direction}
          isExpandingAll={requestParams.max_depth === 'max'}
          isLoading={isFetching}
          isLoadingExportCsv={isLoadingExportCsv}
          loadLineage={loadLineage}
          nodeKey={nodeKey}
          onDataLoad={handleDataLoad}
          onExportCsvClick={enableExportCsv ? handleExportCsvClick : undefined}
          showDescriptions={showDescriptions}
          showExpandAll={lineageDataMerged?.tables.length > 0}
          showUsage={showUsage}
          startingGuid={tableOrColumnId}
          tables={lineageDataMerged.tables}
          type={mode}
          zoomOnItemClick={false}
        />
      ) : (
        <SidebarTree
          columns={lineageDataMerged.columns}
          counts={counts}
          customSearchOptions={customSearchOptions}
          direction={direction}
          isExpandingAll={requestParams.max_depth === 'max'}
          isLoading={isFetching}
          isLoadingExportCsv={isLoadingExportCsv}
          loadLineage={loadLineage}
          nodeKey={nodeKey}
          onExportCsvClick={enableExportCsv ? handleExportCsvClick : undefined}
          onUseRelevantLineageChange={handleUseRelevantLineageChange}
          showDataSourcesFilter
          showDescriptions={showDescriptions}
          showExpandAll={lineageDataMerged?.tables.length > 0}
          showRelevantLineageToggle={showRelevantLineageToggle}
          showUsage={showUsage}
          startingGuid={tableOrColumnId}
          tables={lineageDataMerged.tables}
          type={mode}
          useRelevantLineage={requestParams.relevant_lineage}
          zoomOnItemClick={false}
        />
      )}
    </Box>
  );
};

export default UpstreamDownstreamTab;
