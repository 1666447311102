import styled from '@emotion/styled';

import Box from '@components/Box';
import px from '@styles/mixins/px';

const PRIMARY_SIDEBAR_EXPANDED_WIDTH = 230;
const PRIMARY_SIDEBAR_COLLAPSED_WIDTH = 65;
export const PRIMARY_SIDEBAR_TRANSITION_DURATION = 300; // in ms

interface StyledPrimarySidebarProps {
  isCollapsed?: boolean;
}

export const StyledPrimarySidebar = styled.nav<StyledPrimarySidebarProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-right: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
  height: 100%;
  transition: width ${PRIMARY_SIDEBAR_TRANSITION_DURATION}ms ease;

  width: ${({ isCollapsed }) =>
    px(isCollapsed ? PRIMARY_SIDEBAR_COLLAPSED_WIDTH : PRIMARY_SIDEBAR_EXPANDED_WIDTH)};
`;

interface StyledPrimarySidebarLogoContainerProps {
  shouldCenterContent?: boolean;
}

export const StyledPrimarySidebarLogoContainer = styled(
  Box,
)<StyledPrimarySidebarLogoContainerProps>`
  justify-content: ${({ shouldCenterContent }) => (shouldCenterContent ? 'center' : 'flex-start')};
  padding-left: ${({ shouldCenterContent, theme }) => theme.space(shouldCenterContent ? 0 : 2.75)};
`;

StyledPrimarySidebarLogoContainer.defaultProps = {
  alignItems: 'center',
  borderColor: 'gray.200',
  borderStyle: 'solid',
  borderWidth: '1px',
  compDisplay: 'flex',
  compHeight: '60px',
  compWidth: '100%',
};

export const StyledPrimarySidebarLinksSection = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.v1.gray[200]};
`;

StyledPrimarySidebarLinksSection.defaultProps = {
  compDisplay: 'flex',
  compWidth: '100%',
  flexDirection: 'column',
  gap: 0.5,
  px: 1.5,
  py: 1,
};

interface StyledPrimarySidebarLinkProps {
  isActive?: boolean;
  isSidebarCollapsed?: boolean;
}

export const StyledPrimarySidebarLink = styled(Box)<StyledPrimarySidebarLinkProps>`
  transition: all 0.15s ease;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.v1.primary[100] : theme.colors.white};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.v1.primary[500] : theme.colors.v1.gray[700]};
  justify-content: ${({ isSidebarCollapsed }) => (isSidebarCollapsed ? 'center' : 'flex-start')};

  &:hover {
    color: ${({ theme }) => theme.colors.v1.gray[700]};
    background-color: ${({ theme }) => theme.colors.v1.gray[100]};
  }
`;

StyledPrimarySidebarLink.defaultProps = {
  alignItems: 'center',
  borderRadius: 'md',
  compDisplay: 'flex',
  compHeight: '36px',
  fontSize: 'body2',
  fontWeight: 'medium',
  gap: 1.5,
  px: 1.5,
  whiteSpace: 'nowrap',
};
