import React from 'react';

import { useFetchJobs } from '@api/jobs';
import Box from '@components/Box';
import {
  StyledDetailsListItem,
  StyledDetailsListItemLabel,
} from '@components/DetailsSection/DetailsSection.styles';
import Mention from '@components/Mention';

export interface JobsProps {
  guid: string;
}

const Jobs: React.FC<JobsProps> = ({ guid }) => {
  const { data } = useFetchJobs({
    enabled: Boolean(guid),
    params: {
      ddl_only: 'true',
      target_tables: guid,
    },
  });

  if (!data || data.results.length === 0) {
    return null;
  }

  return (
    <StyledDetailsListItem aria-label="Airflow DAG">
      <StyledDetailsListItemLabel>Airflow DAG</StyledDetailsListItemLabel>
      <Box compDisplay="flex" flexWrap="wrap" gap={0.25}>
        {data.results.map((job) => (
          <Mention
            key={job.guid}
            dataTypes={job?.dataTypes}
            guid={job?.guid}
            isExternalLink
            link={{ pathname: job.jobUrl }}
            name={job?.name}
          />
        ))}
      </Box>
    </StyledDetailsListItem>
  );
};

export default Jobs;
