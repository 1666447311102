import { Location } from '@routing/history';

import mapDataTypesModel from '@models/DataTypesModel/DataTypesModel.v1';
import type { DashboardsPageProps } from '@pages/DashboardsPage/DashboardsPage.v1';

interface GetContainerPagesMappingResult
  extends Pick<
    DashboardsPageProps,
    'dataTypes' | 'countItemsConfig' | 'filterProps' | 'dataTypeOptions'
  > {
  title: string;
}

/**
 * @todo Architecture.
 * Whenever we create container pages in frontend (e.g. /looker/ds_RueDSe9gSahU3ed93skbJw/dashboards),
 * we need to map url to dataTypes. This how it was introduced long time ago.
 *
 * However, it is not scalable and effective. Probably there are much better approaches,
 * which need to be evaluated during links refactoring phase.
 */
const getContainerPagesMapping = (
  location: Location<unknown>,
): GetContainerPagesMappingResult | undefined => {
  if (location.pathname.includes('/periscope')) {
    return {
      countItemsConfig: [{ key: 'count', text: 'Dashboard' }],
      dataTypes: mapDataTypesModel({
        data_source_type: 'periscope',
        object_type: 'bifolder',
      }),
      filterProps: {
        showDescriptionFilter: true,
        showLoadingIssuesFilter: true,
      },
      title: 'Dashboards',
    };
  }

  if (location.pathname.includes('/sigma')) {
    return {
      countItemsConfig: [{ key: 'count', text: 'Workbook' }],
      dataTypes: mapDataTypesModel({
        data_source_type: 'sigma',
        object_type: 'bifolder',
      }),
      title: 'Workbooks',
    };
  }

  if (location.pathname.includes('/metabase')) {
    return {
      countItemsConfig: [{ key: 'count', text: 'Dashboard' }],
      dataTypes: mapDataTypesModel({
        data_source_type: 'metabase',
        object_type: 'metabase_folders_container',
      }),
      filterProps: {
        showDescriptionFilter: true,
        showLoadingIssuesFilter: true,
      },
      title: 'All Dashboards',
    };
  }

  if (location.pathname.includes('/data-studio')) {
    return {
      countItemsConfig: [{ key: 'count', text: 'Report' }],
      dataTypes: mapDataTypesModel({
        data_source_type: 'data_studio',
        object_type: 'bifolder',
      }),
      title: 'All Reports',
    };
  }

  if (location.pathname.includes('/mode/') || location.pathname.includes('/spaces/')) {
    return {
      countItemsConfig: [
        { key: 'folders_count', text: 'Space' },
        { key: 'dashboard_count', text: 'Report' },
      ],
      dataTypes: mapDataTypesModel({
        data_source_type: 'mode',
        object_type: 'collections_container',
      }),
      filterProps: {
        isCollections: true,
        showLoadingIssuesFilter: true,
      },
      title: 'All Reports',
    };
  }

  if (location.pathname.includes('/looker') || location.pathname.includes('/folders')) {
    return {
      countItemsConfig: [
        { key: 'dashboard_count', text: 'Dashboard' },
        { key: 'looks_count', text: 'Look' },
      ],
      dataTypeOptions: [
        { key: 'all', text: 'All', value: null },
        { key: 'dashboards', text: 'Dashboards', value: 'dashboards' },
        { key: 'looks', text: 'Looks', value: 'looks' },
      ],
      dataTypes: mapDataTypesModel({
        data_source_type: 'looker',
        object_type: 'folders_container',
      }),
      title: 'Dashboards & Looks',
    };
  }

  return undefined;
};

export default getContainerPagesMapping;
