import styled from '@emotion/styled';

export const StyledLayout = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
`;

export const StyledLayoutWrapper = styled(StyledLayout)`
  flex-direction: column;
`;

export const LayoutContent = styled.div`
  display: flex;
  flex-grow: 1;
`;
