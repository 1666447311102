import React from 'react';

import useLocalStorage from '@utils/useLocalStorage';

import { SidebarItem, SidebarSection } from '../AppMainSidebar.types';

import {
  StyledSecondarySidebar,
  StyledSecondarySidebarContent,
  StyledSecondarySidebarTitle,
} from './SecondarySidebar.styles';

const SIDEBAR_DEFAULT_WIDTH = 264;

interface SecondarySidebarProps {
  activeMenuItemId: string;
  isVisible: boolean;
  sections?: SidebarSection[];
}

const SecondarySidebar = ({
  activeMenuItemId,
  isVisible,
  sections = [],
}: SecondarySidebarProps) => {
  const items = sections.flatMap((section) => section.items);

  const menuItemsById = items.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {} as Record<string, SidebarItem>);

  const activeMenuItem = menuItemsById[activeMenuItemId] as SidebarItem | undefined;

  const [sidebarWidth, setSidebarWidth] = useLocalStorage('sidebarWidth', SIDEBAR_DEFAULT_WIDTH);
  return (
    <StyledSecondarySidebar
      aria-expanded={isVisible}
      data-testid="secondary-sidebar"
      defaultSize={{
        height: '100%',
        width: sidebarWidth,
      }}
      enable={{ right: true }}
      isVisible={isVisible}
      maxWidth={400}
      minWidth={200}
      onResizeStop={(_e, _direction, ref) => {
        setSidebarWidth(ref.offsetWidth);
      }}
    >
      {activeMenuItem && (
        <>
          <StyledSecondarySidebarTitle>{activeMenuItem.label}</StyledSecondarySidebarTitle>
          <StyledSecondarySidebarContent>{activeMenuItem.children}</StyledSecondarySidebarContent>
        </>
      )}
    </StyledSecondarySidebar>
  );
};

export default SecondarySidebar;
