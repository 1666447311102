import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { RouterLink } from '@routing/router';

import { MENU_ITEM_WIDTH } from '@components/AppMainSidebar/AppMainSidebar.styles';
import Box from '@components/Box';
import px from '@styles/mixins/px';

export const StyledAppHeader = styled(Box)`
  align-items: center;
  background: ${({ theme }) => theme.colors.darkBackground};
  display: flex;
  height: 60px;
  width: 100%;
  padding-left: ${px(MENU_ITEM_WIDTH / 2.5)};
  padding-right: ${({ theme }) => theme.space(3.5)};
  pointer-events: auto;
`;

interface LogoImgProps {
  animate?: boolean;
  compHeight?: string;
}

const logoFadeInAnimation = keyframes`
  0% {
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
`;

export const LogoImg = styled.img<LogoImgProps>`
  height: ${({ compHeight }) => compHeight};
  width: auto;
  animation-play-state: ${({ animate }) => (animate ? 'running' : 'paused')};
  animation: ${logoFadeInAnimation} 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;

interface LogoLinkProps {
  disableAccess: boolean;
}

export const LogoLink = styled(RouterLink)<LogoLinkProps>`
  display: block;
  flex-shrink: 0;
  pointer-events: ${({ disableAccess }) => (disableAccess ? 'none' : 'auto')};
`;
