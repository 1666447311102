import React, { Dispatch, SetStateAction } from 'react';
import iconsConfigMap from '@configs/icons/config';
import { DEFAULT_DOCS_URL } from '@constants';

import Alert from '@components/Alert';
import Box from '@components/Box';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import Link from '@components/Link';
import { ModalFooter } from '@components/UI/Modal';
import Select from '@components/UI/Select';
import { useUserContext } from '@context/User';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';
import { DataSourceOptions } from '@models/DataSourceOptions';

import { StyledLabel } from '../../DataSourceSetup.styles';

import DataSourceAddStepError from './DataSourceAddStepError';
import forms from './forms';

const Empty = () => null;

interface DataSourceAddStepProps {
  dataSource?: DataSourceModel;
  dataType: DataSourceTypesType;
  onDataSourceAdded: (dataSource: DataSourceModel) => void;
  setDataType: Dispatch<SetStateAction<DataSourceTypesType>>;
}

const DataSourceAddStep: React.FC<DataSourceAddStepProps> = ({
  dataSource,
  dataType,
  onDataSourceAdded,
  setDataType,
}) => {
  const { settings } = useUserContext();
  const DataSourceForm = forms?.[dataType] ?? Empty;

  const filteredDataSourceOptions = settings
    ? DataSourceOptions.filter(settings?.filterDisabledIntegrations)
    : [];

  const dataSourceOptions = filteredDataSourceOptions.map((option) => ({
    ...option,
    icon: iconsConfigMap[option.value]?.default ?? 'question',
  }));

  const selectedOption = dataSourceOptions.filter((option) => option.value === dataType);

  return (
    <DataSourceForm
      dataSource={dataSource}
      dataType={dataType}
      name={dataType ? selectedOption?.[0]?.text : ''}
      onSuccess={onDataSourceAdded}
      renderBefore={({ error }) => (
        <>
          <Box gridColumn="1/3">
            <Alert>
              We recommend creating a service user account for Select Star in your DWH or BI. See
              the instructions in our&nbsp;
              <Link
                fontSize="inherit"
                href={`${DEFAULT_DOCS_URL}/integrations`}
                rel="noopener noreferrer"
                target="_blank"
                underline
              >
                documentation
              </Link>
              .
            </Alert>
          </Box>
          <StyledLabel as="div">
            Source Type
            <Select
              containerId="ds-form-grid"
              error={error?.data?.type}
              helperText={error?.data?.type}
              isDisabled={!!dataSource}
              maxOptionsVisible={7}
              onChange={(newValue) => setDataType(newValue?.[0]?.value as DataSourceTypesType)}
              options={dataSourceOptions}
              placeholder="Source Type"
              showClearSelection={false}
              value={selectedOption}
            />
          </StyledLabel>
        </>
      )}
    >
      {({ error, loading }) => (
        <>
          <DataSourceAddStepError error={error} pb={2} />
          <ModalFooter>
            <NextStepButton disabled={loading} text="Save" />
          </ModalFooter>
        </>
      )}
    </DataSourceForm>
  );
};

export default DataSourceAddStep;
